import React, { useState, useEffect, Fragment } from "react";
import JobDescription from "./JobDescription";
import { calculateJobLinesTotal } from "../../utils/quoteUtils";
import CurrencyFormatter from "../Shared/CurrencyFormatter";
import { getCurrencies } from "../../utils/general";

const Production = (props) => {
  const { job, company } = props;

  const [hasItemisation, setHasItemisation] = useState(false);

  useEffect(() => {
    if (job.jobLines.length > 0) {
      job.jobLines.every((jl) => {
        if (jl.isPassThrough === 1) {
          setHasItemisation(true);
          return false;
        }
        // Make sure you return true. If you don't return a value, `every()` will stop.
        return true;
      });
    }
  }, [job]);

  const getProductionRowPassThrough = () => {
    const symbol = getCurrencies().find((c) => c.value === job.currency).symbol;
    var multiplier = 1;
    if (job.isRetainer) {
      if (job.retainerType === "TOTAL") {
        multiplier = job.retainerDuration;
      } else if (job.retainerType === "QUARTERLY") {
        multiplier = 3;
      } else if (job.retainerType === "YEARLY") {
        multiplier = 12;
      }
    }

    const categoriesGuid = job?.jobCategories
      .filter(
        (c) =>
          c.type === "Pre Production" ||
          c.type === "Post Production" ||
          c.type === "CapEx Mag" ||
          c.type === "Filming"
      )
      .map((c) => c.guid);

    // eslint-disable-next-line array-callback-return
    const lines = job.jobLines
      .filter(
        (jl) =>
          categoriesGuid.includes(jl.jobCategoryGuid) && jl.isPassThrough === 1
      )
      .map((jl) => {
        return (
          <tr className="use-sf-pro">
            <td>{jl.name}</td>
            <td>{jl.description}</td>
            <td>{jl.quantity}</td>
            <td>
              {jl.rateType === "Open Budget" ? jl.exchangedTotal : jl.sellPrice}
            </td>
            <td align="right">{`${symbol || "$"}${
              jl.exchangedTotal * multiplier
            }`}</td>
          </tr>
        );
      });
    return lines;
  };

  const getProductionRow = () => {
    const symbol = getCurrencies().find((c) => c.value === job.currency).symbol;
    let ProdTotal = 0;
    const categoriesGuid = job?.jobCategories
      .filter(
        (c) =>
          c.type === "Pre Production" ||
          c.type === "Post Production" ||
          c.type === "CapEx Mag" ||
          c.type === "Filming" ||
          c.type === "Other"
      )
      .map((c) => c.guid);
    job.jobLines.forEach((jl) => {
      if (
        categoriesGuid.includes(jl.jobCategoryGuid) &&
        jl.isPassThrough !== 1
      ) {
        ProdTotal += jl.exchangedTotal;
      }
    });
    var multiplier = 1;
    if (job.isRetainer) {
      if (job.retainerType === "TOTAL") {
        multiplier = job.retainerDuration;
      } else if (job.retainerType === "QUARTERLY") {
        multiplier = 3;
      } else if (job.retainerType === "YEARLY") {
        multiplier = 12;
      }
    }
    return (
      <tr className="use-main-font">
        {hasItemisation ? (
          <td colSpan={4}>{`All other production costs of ${job.jobName}`}</td>
        ) : (
          <td
            colSpan={4}
          >{`All inclusive production costs of ${job.jobName}`}</td>
        )}
        <td align="right">{`${symbol || "$"}${ProdTotal * multiplier}`}</td>
      </tr>
    );
  };

  const GetJobLineTotal = (cat) => {
    var multiplier = 1;
    if (job.isRetainer) {
      if (job.retainerType === "TOTAL") {
        multiplier = job.retainerDuration;
      } else if (job.retainerType === "QUARTERLY") {
        multiplier = 3;
      } else if (job.retainerType === "YEARLY") {
        multiplier = 12;
      }
    }
    return (
      <CurrencyFormatter
        amount={
          calculateJobLinesTotal(
            job.jobLines?.filter((jl) => jl.jobCategoryGuid === cat.guid)
          ) * multiplier
        }
        appendZero
        useDollar
        currency={job.currency || "NZD"}
      />
    );
  };

  return (
    <div id="quote-sections" className="quote-categories-container">
      {job?.jobCategories.filter(
        (c) =>
          c.type === "Pre Production" ||
          c.type === "Post Production" ||
          c.type === "CapEx Mag" ||
          c.type === "Filming" ||
          c.type === "Other"
      ).length > 0 && (
        <table className="magnesium-table">
          <thead>
            <tr className="">
              <th colSpan={5} align="left">
                Production
              </th>
            </tr>
          </thead>
          <tbody>
            {hasItemisation ? (
              <tr className="">
                <td>Item</td>
                <td>Description</td>
                <td>Quantity</td>
                <td>Cost</td>
                <td align="right">Total</td>
              </tr>
            ) : (
              <tr>
                <td colSpan={4}>Item</td>
                <td align="right">Total</td>
              </tr>
            )}
            {getProductionRow()}
            {hasItemisation && getProductionRowPassThrough()}
          </tbody>
        </table>
      )}

      {job?.jobCategories
        .filter(
          (c) =>
            c.type !== "Pre Production" &&
            c.type !== "Post Production" &&
            c.type !== "CapEx Mag" &&
            c.type !== "Filming" &&
            c.type !== "Other"
        )
        .map((cat) => {
          return (
            <table className="magnesium-table">
              <thead>
                <tr>
                  <th colSpan={4} align="left" className="item-title">
                    <b>{cat.name}</b> {cat.type === "Media Spend" && <span>(billed as run)</span>}
                  </th>
                  <th colSpan={1} align="right" className="item-total">
                    {GetJobLineTotal(cat)}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={5}>
                    {cat?.description && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `${cat.description}`,
                        }}
                      />
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          );
        })}
    </div>
  );
};

export default Production;
