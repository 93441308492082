export const calculateJobLinesTotal = (jobLines) => {
  return jobLines.reduce((a, b) => a + b.exchangedTotal, 0);
};
export const formatNumber = (num, decimals = 2) => {
  if (num == null || isNaN(num)) {
    return ""; // Return an empty string for null or undefined values
  }

  // Use toFixed(2) to format the number with two decimal places
  return Number(num).toFixed(decimals);
};
