import React, { useEffect, useState, useRef } from "react";
import {
    Dialog,
    DialogTitle,
    IconButton,
    DialogContent,
    Grid,
    Divider,
    CircularProgress
} from "@mui/material";
import {
    Close as CloseIcon
} from "@mui/icons-material";
import "./Signature.scss";
import SignaturePad from 'react-signature-canvas'


const SignatureComponent = (props) => {
    const {
        open,
        handleClose,
        title,
        handleAcceptAndSign
    } = props;
    let sigPad = useRef({});

    const [isMounted, setIsMounted] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSave = () => {
        var dataUrl = sigPad.current.toDataURL();
        var isEmpty = sigPad.current.isEmpty();
        if (isEmpty) {
            alert("Signature cannot be empty")
        } else {
            setLoading(true)
            handleAcceptAndSign(dataUrl);
        }
    };

    const handleClear = () => {
        sigPad.current.clear();
    };

    useEffect(() => {
        if (!isMounted) {
            setIsMounted(true);
        }
    }, [isMounted, setIsMounted]);

    return (
        <Dialog
            open={open}
            onClose={(e, reason) => {
                if (reason && reason === "backdropClick") {
                    return;
                }
                handleClose();
            }}
            classes={{ paper: "signature-preview-dialog" }}
        >
            <DialogTitle>
                <div>
                    {title}
                </div>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <div id="signature-pad">
                    <SignaturePad ref={sigPad} canvasProps={{ className: "signature-pad-canvas" }} />
                    <div>
                        {loading ? <CircularProgress /> :
                            <React.Fragment>
                                <button onClick={() => handleClear()} className="use-main-font clear-button quote-button">
                                    Clear
                                </button>
                                <button onClick={() => handleSave()} className="use-main-font save-button quote-button">
                                    Save
                                </button>
                            </React.Fragment>
                        }
                    </div>
                </div>
            </DialogContent>
        </Dialog >
    )
}

export default SignatureComponent;