/**
 * Is value numeric
 * 
 * Determine whether variable is a number
 * 
 * @param {*} str 
 *
  import { isNumeric } from '../helpers/general'

  isNumeric(value)
*/
function isNumeric(str) {
  if (['string', 'number'].indexOf(typeof str) === -1) return false; // we only process strings and numbers!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

/**
 * Validate email format
 * 
 * Checks the provided email address and validates its format
 * 
 * @param   {String} email  The email address
 * 
    import { validateEmail } from '../helpers/general'

    validateEmail(email)
 */
function validateEmail(email) {
  const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email?.trim())
  
  return isValid;
}

/**
 * Validate strong password format
 * 
 * 
 * @param   {String} password  The password
 * 
    import { validateStrongPassword } from '../helpers/general'

    validateStrongPassword(email)
 */
function validateStrongPassword(password) {
  return /(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/.test(password);
}

/**
 * Checks for empty string
 * 
 * @param   {String} email  input
 * 
    import { isEmpty } from '../helpers/general'

    isEmpty(email)
 */
function isEmpty(input) {
  if (input === '' || input === null || input === undefined || input === " ") return true;
}

/**
 * Checks if user is authenticated
 * 
 * 
 * 
    import { isAuth } from '../helpers/general'

    isAuth()
 */
function isAuth() {
  const isBrowser = typeof window !== 'undefined';
  if (isBrowser) {
    const token = window.localStorage.getItem('key');
    if (token) return true;
    else return false;
  } else {
    return true;
  }
}

function capitalizeWord(word) {
  if(word && word.length > 1){
    return word[0].toUpperCase() + word.substring(1).toLowerCase();
  }
  return word
}

function internationalNumberFormat(num) {
  if (num == null || isNaN(num)) {
    return 0; // Return an empty string for null or undefined values
  }
  const formatter = Intl.NumberFormat('en', {notation: 'compact'})
  return formatter.format(num)
}

function checkIfThompsonSpencerUrl(url) {
  if(url && url.includes("talent-management")){
    return true;
  }
  return false;
}

function getCurrencies(shouldExcludeNZ = false) {
  let currenciesWithoutNZ = [
    {
      value: "AUD",
      code: "AU",
      symbol: "AU$",
    },
    {
      value: "EUR",
      code: "EU",
      symbol: "€",
    },
    {
      value: "GBP",
      code: "GB",
      symbol: "£",
    },
    {
      value: "USD",
      code: "US",
      symbol: "US$",
    },
    {
      value: "JPY",
      code: "JP",
      symbol: "¥",
    },
    {
      value: "CNY",
      code: "CN",
      symbol: "¥",
    },
  ];
  if (shouldExcludeNZ) {
    return currenciesWithoutNZ;
  } else {
    return [
      {
        value: "NZD",
        code: "NZ",
        symbol: "$",
      },
      ...currenciesWithoutNZ,
    ];
  }
}

function currencyNumberFormat(num){
  if (num == null || isNaN(num)) {
    return 0; 
  }
  return num.toFixed(2);
}

function currencySymbolFormat(num, currency = "NZD"){
  const symbol = getCurrencies().find(c => c.value === currency).symbol;
  if (num == null || isNaN(num)) {
    return `${symbol}0`; 
  }
  return `${symbol}${currencyNumberFormat(num).toLocaleString("en-NZ", {
    minimumFractionDigits: 2,
  })}`;
}
export { isNumeric, validateEmail, validateStrongPassword, isEmpty, isAuth, capitalizeWord, internationalNumberFormat, checkIfThompsonSpencerUrl, getCurrencies, currencySymbolFormat, currencyNumberFormat };
