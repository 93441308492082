import React from "react";
import ThompsonSpencerLogoNoBg from "../Icons/ThompsonSpencerLogoNoBg";
import BrandsLogoNoBg from "../Icons/BrandsLogoNoBg";

const DefaultHeader = () => {
  return (
    <div className="default-header-root">
      <ThompsonSpencerLogoNoBg />
      <BrandsLogoNoBg />
    </div>
  );
};

export default DefaultHeader;
