import React from "react";

const BrandsLogoNoBg = () => {
  return (
    <svg
      width="367"
      height="31"
      viewBox="0 0 367 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M84.4377 3.92528H82.5751V18.7348H84.4377V3.92528Z"
        fill="white"
      />
      <path
        d="M89.9913 18.7348L86.3002 3.92528H84.4377L88.0719 18.7348H89.9913Z"
        fill="white"
      />
      <path
        d="M97.4074 3.92528H95.5448V18.7348H97.4074V3.92528Z"
        fill="white"
      />
      <path
        d="M90.0026 18.7348H91.922L95.5448 3.92528H93.6823L90.0026 18.7348Z"
        fill="white"
      />
      <path
        d="M72.9103 22.812C72.9103 22.812 72.8535 22.8347 72.8422 22.8688C72.3993 24.595 71.9563 26.3327 71.5021 28.0589C71.0478 26.3327 70.6049 24.6064 70.1619 22.8688C70.1619 22.8347 70.1279 22.812 70.0938 22.812H68.6174C68.6174 22.812 68.5492 22.8461 68.5492 22.8801V28.7063C68.5492 28.7063 68.5833 28.7744 68.6174 28.7744H69.3897C69.3897 28.7744 69.4237 28.7744 69.4351 28.7517C69.4464 28.7403 69.4578 28.7176 69.4578 28.7063C69.4578 26.98 69.4578 25.39 69.4578 23.8682C69.9007 25.5831 70.3096 27.1504 70.6957 28.6608C70.6957 28.6949 70.7298 28.7176 70.7639 28.7176C71.2976 28.7176 71.786 28.7176 72.2403 28.7063C72.2743 28.7063 72.2971 28.6836 72.3084 28.6495L73.5463 23.9023C73.5463 25.5036 73.5463 27.1049 73.5463 28.7063C73.5463 28.729 73.5463 28.7403 73.569 28.7517C73.5804 28.7631 73.6031 28.7744 73.6145 28.7744H74.3981C74.3981 28.7744 74.4662 28.7403 74.4662 28.7063V22.8801C74.4662 22.8801 74.4322 22.812 74.3981 22.812H72.9103Z"
        fill="white"
      />
      <path
        d="M77.8847 27.4229C77.6462 27.9226 77.2714 28.1271 76.7036 28.0248C76.5446 28.0021 76.4196 27.934 76.3174 27.8204C76.272 27.7523 76.2493 27.6841 76.2493 27.6046C76.2493 27.4229 76.2493 27.2412 76.2493 27.0709C76.2493 26.9005 76.3288 26.821 76.4878 26.8097C77.0329 26.7642 77.5326 26.7074 78.0096 26.662C78.0096 26.9232 77.9756 27.1844 77.8847 27.4229ZM78.9182 27.8999C78.9182 27.0368 78.9182 26.2418 78.9182 25.4809C78.8955 24.9925 78.6683 24.6064 78.2595 24.3338C77.8506 24.118 77.3963 24.0385 76.9307 24.084C76.6468 24.118 76.3629 24.2089 76.113 24.3679C75.6814 24.6518 75.4543 25.0493 75.4316 25.5717C75.4316 25.5831 75.4316 25.6058 75.4543 25.6285C75.4657 25.6399 75.4884 25.6512 75.4997 25.6512H76.2834C76.2834 25.6512 76.3174 25.6512 76.3401 25.6285C76.3515 25.6172 76.3629 25.5944 76.3629 25.5717C76.3515 25.2083 76.5105 24.9925 76.8399 24.8903C77.2941 24.7995 77.6121 24.8449 77.8052 25.0266C77.9869 25.197 78.0664 25.5036 78.021 25.9238C77.5553 25.9579 77.0784 26.0033 76.5559 26.0601C76.0676 26.1055 75.6928 26.344 75.4316 26.7642C75.2158 27.2412 75.2272 27.7182 75.4884 28.1952C75.795 28.6154 76.2039 28.8198 76.7263 28.8198C76.7376 28.8198 76.7604 28.8198 76.7717 28.8198C77.3736 28.8198 77.8166 28.5813 78.1005 28.1043C78.1005 28.2861 78.1005 28.4678 78.1005 28.6381C78.1005 28.6608 78.1005 28.6722 78.1232 28.6949C78.1346 28.7063 78.1573 28.7176 78.1686 28.7176H79.486C79.486 28.7176 79.5542 28.6836 79.5542 28.6495V27.9794C79.5542 27.9794 79.5201 27.9113 79.486 27.9113H78.9068L78.9182 27.8999Z"
        fill="white"
      />
      <path
        d="M82.825 27.8659C82.5524 28.0249 82.2571 28.0816 81.9392 28.0248C81.5757 27.9567 81.3259 27.7636 81.1782 27.4456C81.1555 27.3889 81.1442 27.3321 81.1328 27.2753C81.1328 26.7302 81.1328 26.1737 81.1328 25.6512C81.1896 25.3105 81.3827 25.072 81.7007 24.9471C81.8142 24.9017 81.9164 24.879 82.03 24.8676C82.0641 24.8676 82.0868 24.8676 82.1209 24.8676C82.7909 24.8676 83.1657 25.2083 83.2679 25.8784C83.2906 26.2191 83.2906 26.5712 83.2793 26.9232C83.2793 27.3321 83.1203 27.6387 82.8136 27.8886M83.3588 24.1748C83.3588 24.1748 83.3247 24.1748 83.3133 24.1975C83.302 24.2089 83.2906 24.2316 83.2906 24.243C83.2906 24.402 83.2906 24.5496 83.2906 24.6972C83.0294 24.3452 82.666 24.1408 82.2117 24.084C81.7461 24.0158 81.3145 24.1294 80.9397 24.4133C80.6217 24.6859 80.406 25.0266 80.3037 25.4241C80.1788 25.9124 80.1334 26.4122 80.1902 26.9005C80.2356 27.3321 80.3719 27.7296 80.599 28.1043C80.8943 28.5246 81.3032 28.7631 81.8142 28.8085C82.4502 28.8653 82.9499 28.6608 83.2906 28.1838C83.2906 28.3883 83.2906 28.6041 83.2679 28.8085C83.1884 29.206 82.9613 29.4558 82.5751 29.5694C82.2912 29.6375 82.0073 29.6262 81.7234 29.5353C81.4394 29.4218 81.2804 29.2173 81.2464 28.9107C81.2464 28.8766 81.2123 28.8539 81.1782 28.8539H80.406C80.406 28.8539 80.3719 28.8539 80.3605 28.8766C80.3492 28.888 80.3378 28.9107 80.3378 28.9334C80.3946 29.524 80.6899 29.9442 81.2237 30.194C81.5076 30.3076 81.8029 30.3757 82.1095 30.3871H82.4048C82.6206 30.3757 82.8364 30.3303 83.0635 30.2508C83.7563 29.9896 84.1538 29.4672 84.2219 28.7176C84.2219 27.3094 84.2219 25.8443 84.2219 24.243C84.2219 24.2089 84.1878 24.1748 84.1538 24.1748H83.3588Z"
        fill="white"
      />
      <path
        d="M88.9805 25.2083C88.9124 24.7881 88.6852 24.4587 88.3104 24.2316C87.9924 24.0953 87.6517 24.0385 87.2883 24.0726C86.8567 24.1294 86.516 24.3338 86.2889 24.6745C86.2889 24.5382 86.2889 24.3906 86.2889 24.2316C86.2889 24.2202 86.2889 24.1975 86.2662 24.1862C86.2548 24.1748 86.2321 24.1635 86.2207 24.1635H85.4371C85.4371 24.1635 85.369 24.1975 85.369 24.2316V28.6268C85.369 28.6268 85.403 28.6949 85.4371 28.6949H86.2207C86.2207 28.6949 86.2548 28.6949 86.2662 28.6722C86.2775 28.6608 86.2889 28.6381 86.2889 28.6268C86.2889 27.6841 86.2889 26.821 86.2889 26.0033C86.2889 25.8557 86.323 25.7194 86.357 25.5831C86.4706 25.231 86.6864 24.9925 87.0157 24.8676C87.3678 24.7881 87.629 24.8222 87.7994 24.9585C87.9697 25.0947 88.0719 25.3332 88.0833 25.6853C88.0833 26.6052 88.0833 27.5706 88.0833 28.6268C88.0833 28.6495 88.0833 28.6608 88.106 28.6722C88.1174 28.6836 88.1401 28.6949 88.1514 28.6949H88.9351C88.9351 28.6949 89.0032 28.6608 89.0032 28.6268C89.0032 27.5251 89.0032 26.4235 89.0032 25.4922C89.0032 25.4014 88.9805 25.2992 88.9691 25.197"
        fill="white"
      />
      <path
        d="M90.6273 25.9692C90.6046 25.6967 90.6727 25.4582 90.8203 25.231C91.002 25.0152 91.2292 24.8903 91.5131 24.8449C91.5472 24.8449 91.5812 24.8449 91.6153 24.8449C91.9333 24.8449 92.2059 24.9585 92.4217 25.1856C92.592 25.4127 92.6602 25.6853 92.6375 25.9579H90.6273V25.9692ZM93.5801 26.6734C93.5801 26.4462 93.5801 26.2418 93.5801 26.0487C93.5801 25.8897 93.546 25.7421 93.5233 25.6058C93.4097 25.038 93.1144 24.595 92.6147 24.2998C92.2286 24.1067 91.8311 24.0385 91.3995 24.084C90.5818 24.2203 90.0481 24.6972 89.8096 25.4922C89.6278 26.2077 89.6392 26.9232 89.8777 27.6274C90.0594 28.1271 90.4115 28.4905 90.8998 28.6949C91.1497 28.7858 91.4109 28.8198 91.6608 28.8198C92.0128 28.8198 92.3649 28.729 92.7056 28.5586C93.228 28.2747 93.5119 27.8318 93.5687 27.2412C93.5687 27.2185 93.5687 27.2071 93.546 27.1844C93.5347 27.1731 93.5119 27.1617 93.4892 27.1617H92.7056C92.7056 27.1617 92.6375 27.1958 92.6375 27.2299C92.6375 27.4911 92.5239 27.6955 92.3195 27.8545C92.0128 28.0362 91.6835 28.093 91.3428 28.0021C90.968 27.8999 90.7522 27.6614 90.6727 27.298C90.6613 27.1049 90.65 26.9232 90.65 26.7415H93.5233C93.5233 26.7415 93.5914 26.7074 93.5914 26.6734"
        fill="white"
      />
      <path
        d="M96.6124 26.1055C96.3058 26.0374 95.9991 25.9806 95.7038 25.9124C95.6357 25.8897 95.5676 25.867 95.5108 25.8443C95.3972 25.7875 95.3177 25.708 95.2609 25.5944C95.1928 25.3787 95.2382 25.1856 95.3972 25.038C95.6471 24.8676 95.9083 24.8108 96.2149 24.8562C96.6124 24.9357 96.7941 25.1629 96.8055 25.5604C96.8055 25.5944 96.8395 25.6285 96.8736 25.6285H97.6686C97.6686 25.6285 97.7027 25.6285 97.714 25.6058C97.7254 25.5944 97.7367 25.5717 97.7367 25.5604C97.7367 25.0266 97.4983 24.6177 97.0553 24.3225C96.6465 24.1067 96.2036 24.0272 95.7379 24.0726C95.4881 24.1067 95.2382 24.1862 95.0111 24.2998C94.6363 24.5042 94.3978 24.8108 94.3296 25.2424C94.2842 25.5944 94.3637 25.9124 94.5795 26.2077C94.8294 26.4803 95.136 26.6393 95.4994 26.7074C95.7947 26.7642 96.1127 26.8324 96.4648 26.9119C96.6465 26.9573 96.7828 27.0481 96.8963 27.1958C97.0326 27.5819 96.919 27.8204 96.5329 27.9681C96.2376 28.0703 95.9423 28.0703 95.6471 27.9681C95.3177 27.8431 95.1473 27.6046 95.136 27.2412C95.136 27.2071 95.1019 27.1731 95.0678 27.1731H94.2842C94.2842 27.1731 94.2161 27.2071 94.2161 27.2412C94.2161 27.616 94.3296 27.9454 94.5681 28.2406C94.8634 28.5473 95.2269 28.729 95.6471 28.7858C95.7833 28.8085 95.931 28.8198 96.0673 28.8198C96.4875 28.8198 96.8963 28.7063 97.2825 28.4905C97.6686 28.252 97.873 27.8886 97.8844 27.4343C97.8958 27.0254 97.7481 26.6961 97.4187 26.4462C97.1689 26.2872 96.9077 26.1737 96.6124 26.1055Z"
        fill="white"
      />
      <path
        d="M110.229 24.084C109.548 24.0158 109.048 24.2657 108.73 24.8449C108.537 24.4133 108.185 24.1521 107.685 24.084C107.084 24.0158 106.629 24.2089 106.323 24.6745C106.323 24.5382 106.323 24.3906 106.323 24.243C106.323 24.2203 106.323 24.2089 106.3 24.1975C106.289 24.1862 106.266 24.1748 106.255 24.1748H105.471C105.471 24.1748 105.403 24.2089 105.403 24.243V28.6381C105.403 28.6381 105.437 28.7063 105.471 28.7063H106.255C106.255 28.7063 106.289 28.7063 106.3 28.6836C106.311 28.6722 106.323 28.6495 106.323 28.6381C106.323 27.7069 106.323 26.8551 106.323 26.0374C106.323 25.8443 106.368 25.6512 106.436 25.4582C106.538 25.197 106.72 25.0039 106.959 24.9017C107.265 24.8222 107.492 24.8449 107.651 24.9585C107.81 25.072 107.913 25.2878 107.935 25.5944C107.935 26.503 107.935 27.5706 107.935 28.6381C107.935 28.6608 107.935 28.6722 107.958 28.6836C107.969 28.6949 107.992 28.7063 108.003 28.7063H108.798C108.798 28.7063 108.833 28.7063 108.844 28.6836C108.855 28.6722 108.867 28.6495 108.867 28.6381C108.867 27.6501 108.867 26.7529 108.867 25.8897C108.889 25.6626 108.957 25.4355 109.082 25.2424C109.207 25.0493 109.389 24.9244 109.605 24.8562C109.843 24.8108 110.059 24.8562 110.241 24.9925C110.388 25.1742 110.479 25.4014 110.479 25.6399C110.479 26.5825 110.479 27.5592 110.479 28.6381C110.479 28.6608 110.479 28.6722 110.502 28.6836C110.513 28.6949 110.536 28.7063 110.547 28.7063H111.331C111.331 28.7063 111.399 28.6722 111.399 28.6381V25.4582C111.377 25.2992 111.354 25.1742 111.32 25.0266C111.149 24.4701 110.763 24.1521 110.184 24.084"
        fill="white"
      />
      <path
        d="M103.529 24.1748C103.529 24.1748 103.495 24.1748 103.483 24.1975C103.472 24.2089 103.461 24.2316 103.461 24.243C103.461 25.2197 103.461 26.1169 103.461 26.9686C103.461 27.1504 103.404 27.3207 103.336 27.4797C103.199 27.7977 102.961 27.9794 102.62 28.0362C102.336 28.0703 102.121 28.0362 101.973 27.8999C101.825 27.775 101.734 27.5819 101.712 27.298C101.712 26.3327 101.712 25.3332 101.712 24.2316C101.712 24.2089 101.712 24.1975 101.689 24.1862C101.678 24.1748 101.655 24.1635 101.644 24.1635H100.849C100.849 24.1635 100.78 24.1975 100.78 24.2316C100.78 25.3332 100.78 26.344 100.78 27.3207C100.78 27.5024 100.814 27.6841 100.871 27.8545C101.053 28.3996 101.428 28.7063 101.996 28.7858C102.109 28.8085 102.223 28.8198 102.325 28.8198C102.813 28.8198 103.199 28.6154 103.483 28.1952C103.483 28.3315 103.483 28.4791 103.483 28.6268C103.483 28.6495 103.483 28.6608 103.506 28.6722C103.517 28.6836 103.54 28.6949 103.552 28.6949H104.335C104.335 28.6949 104.403 28.6608 104.403 28.6268V24.2316C104.403 24.2316 104.369 24.1635 104.335 24.1635H103.552L103.529 24.1748Z"
        fill="white"
      />
      <path
        d="M98.7929 22.7098H99.6106C99.6106 22.7098 99.6788 22.7438 99.6788 22.7779V23.607C99.6788 23.607 99.6447 23.6751 99.6106 23.6751H98.7929C98.7929 23.6751 98.7248 23.641 98.7248 23.607V22.7779C98.7248 22.7779 98.7589 22.7098 98.7929 22.7098Z"
        fill="white"
      />
      <path
        d="M98.8157 24.1748H99.5993C99.5993 24.1748 99.6674 24.2089 99.6674 24.243V28.6381C99.6674 28.6381 99.6334 28.7063 99.5993 28.7063H98.8157C98.8157 28.7063 98.7475 28.6722 98.7475 28.6381V24.243C98.7475 24.243 98.7816 24.1748 98.8157 24.1748Z"
        fill="white"
      />
      <path
        d="M15.7164 2.00595C15.7164 2.00595 15.6142 2.0173 15.5915 2.07409C15.5802 2.13087 15.5915 2.1763 15.6483 2.19902C15.7051 2.22173 15.7619 2.19902 15.7732 2.13087C15.7846 2.07409 15.7619 2.02866 15.7051 2.00595"
        fill="white"
      />
      <path
        d="M13.9788 4.69756C13.9788 4.69756 14.081 4.6862 14.1037 4.62942C14.1151 4.57263 14.0924 4.5272 14.0356 4.50449C13.9788 4.48177 13.9334 4.51585 13.9107 4.57263C13.8993 4.62942 13.9107 4.67484 13.9788 4.69756Z"
        fill="white"
      />
      <path
        d="M14.8306 11.0348C14.8306 11.0348 14.7284 11.0461 14.7057 11.1029C14.6943 11.1597 14.717 11.2051 14.7738 11.2278C14.8306 11.2506 14.876 11.2165 14.8987 11.1597C14.9101 11.1029 14.8987 11.0575 14.8306 11.0348Z"
        fill="white"
      />
      <path
        d="M17.3519 11.2506C17.3519 11.2506 17.3405 11.1483 17.2837 11.1256C17.2269 11.1029 17.1815 11.1256 17.1588 11.1824C17.1361 11.2392 17.1588 11.2846 17.2156 11.3073C17.2724 11.3301 17.3178 11.3073 17.3405 11.2506"
        fill="white"
      />
      <path
        d="M27.5391 10.8076C27.5732 10.7054 27.5164 10.5918 27.4142 10.5578C27.3119 10.5237 27.1984 10.5805 27.1643 10.6827C27.1302 10.7849 27.187 10.8985 27.2892 10.9326C27.3914 10.9666 27.505 10.9098 27.5391 10.8076Z"
        fill="white"
      />
      <path
        d="M24.4046 11.0688L21.7016 9.04729L19.0668 7.05981C16.4319 5.29948 18.0219 2.40344 18.0219 2.40344C14.7738 4.75434 14.3763 7.84345 16.9771 9.7855L19.68 11.807L22.3149 13.7945C24.9497 15.5549 23.3597 18.4509 23.3597 18.4509C26.6078 16.1 27.0053 13.0222 24.4046 11.0688Z"
        fill="white"
      />
      <path
        d="M22.0082 8.28637C21.9855 8.37723 22.0537 8.47944 22.1445 8.50215C22.2354 8.52487 22.3376 8.45673 22.3603 8.36587C22.383 8.26366 22.3149 8.1728 22.224 8.15009C22.1218 8.12737 22.0309 8.19552 22.0082 8.28637Z"
        fill="white"
      />
      <path
        d="M24.9951 17.6105C24.9951 17.6105 24.9838 17.5083 24.927 17.4855C24.8702 17.4742 24.8248 17.4855 24.802 17.5537C24.7793 17.6105 24.802 17.6559 24.8702 17.6786C24.927 17.69 24.9724 17.6673 24.9951 17.6105Z"
        fill="white"
      />
      <path
        d="M20.6908 18.7916C20.6908 18.7916 20.793 18.7916 20.8157 18.7235C20.8385 18.6667 20.8157 18.6212 20.759 18.5985C20.7022 18.5758 20.6454 18.5985 20.634 18.6553C20.6227 18.7121 20.6454 18.7575 20.7022 18.7802"
        fill="white"
      />
      <path
        d="M21.1905 19.0187C21.1905 19.0187 21.2927 19.0074 21.3154 18.9506C21.3382 18.8938 21.3154 18.8484 21.2587 18.8257C21.2019 18.803 21.1451 18.8257 21.1337 18.8825C21.1224 18.9392 21.1451 18.9847 21.2019 19.0074"
        fill="white"
      />
      <path
        d="M21.0202 17.0994C21.0202 17.0994 21.0315 17.2016 21.0883 17.2243C21.1451 17.2357 21.1905 17.213 21.2132 17.1562C21.2359 17.0994 21.2019 17.054 21.1451 17.0313C21.0883 17.0199 21.0429 17.0426 21.0202 17.0994Z"
        fill="white"
      />
      <path
        d="M21.8947 17.0881C21.8947 17.0881 21.9969 17.0767 22.0196 17.0199C22.0423 16.9631 22.0196 16.9177 21.9628 16.895C21.906 16.8723 21.8606 16.895 21.8379 16.9518C21.8152 17.0086 21.8492 17.054 21.906 17.0767"
        fill="white"
      />
      <path
        d="M22.2694 16.0773C22.2694 16.0773 22.2808 16.1795 22.3376 16.2022C22.3944 16.2136 22.4398 16.1908 22.4625 16.1341C22.4852 16.0773 22.4511 16.0319 22.3944 16.0091C22.3376 15.9978 22.2922 16.0091 22.2694 16.0659"
        fill="white"
      />
      <path
        d="M21.3041 17.6332C21.3041 17.6332 21.2019 17.6332 21.1792 17.7013C21.1564 17.7581 21.1792 17.8035 21.2359 17.8263C21.2927 17.849 21.3495 17.8263 21.3609 17.7695C21.3722 17.7127 21.3495 17.6673 21.2927 17.6445"
        fill="white"
      />
      <path
        d="M21.7129 15.0665C21.7129 15.0665 21.7243 15.1687 21.7811 15.1914C21.8379 15.2028 21.8833 15.1801 21.906 15.1233C21.9287 15.0665 21.8947 15.0211 21.8379 14.9984C21.7811 14.987 21.7357 15.0097 21.7129 15.0665Z"
        fill="white"
      />
      <path
        d="M22.065 16.3839C22.065 16.3839 21.9628 16.3953 21.9401 16.4521C21.9174 16.5088 21.9401 16.5543 21.9969 16.577C22.0537 16.5997 22.0991 16.577 22.1218 16.5202C22.1332 16.4634 22.1218 16.418 22.0537 16.3953"
        fill="white"
      />
      <path
        d="M22.3489 16.8268C22.3489 16.8268 22.3489 16.9291 22.4171 16.9518C22.4739 16.9631 22.5193 16.9518 22.542 16.8836C22.5647 16.8268 22.542 16.7814 22.4739 16.7587C22.4171 16.7473 22.3716 16.7701 22.3489 16.8268Z"
        fill="white"
      />
      <path
        d="M20.0775 19.3367C20.0775 19.3367 20.1797 19.3254 20.2025 19.2686C20.2252 19.2118 20.2025 19.1664 20.1457 19.1437C20.0889 19.121 20.0435 19.1437 20.0207 19.2005C19.998 19.2572 20.0321 19.3027 20.0889 19.3254"
        fill="white"
      />
      <path
        d="M21.7243 18.7007C21.7243 18.7007 21.6221 18.7121 21.5994 18.7689C21.5767 18.8257 21.5994 18.8711 21.6562 18.8938C21.7129 18.9165 21.7584 18.8938 21.7811 18.837C21.8038 18.7802 21.7811 18.7348 21.7243 18.7121"
        fill="white"
      />
      <path
        d="M21.6334 17.4515C21.6334 17.4515 21.6448 17.5537 21.7016 17.5764C21.7584 17.5878 21.8038 17.565 21.8265 17.5083C21.8492 17.4515 21.8152 17.406 21.7584 17.3833C21.7016 17.372 21.6562 17.3947 21.6334 17.4515Z"
        fill="white"
      />
      <path
        d="M22.2127 17.4174C22.2127 17.4174 22.224 17.5196 22.2808 17.5423C22.3376 17.5537 22.383 17.531 22.4057 17.4742C22.4284 17.4174 22.3944 17.372 22.3376 17.3493C22.2808 17.3379 22.2354 17.3493 22.2127 17.406"
        fill="white"
      />
      <path
        d="M22.1786 18.1215C22.1786 18.1215 22.2808 18.1102 22.3035 18.0534C22.3262 17.9966 22.3035 17.9512 22.2467 17.9285C22.1899 17.9058 22.1445 17.9285 22.1218 17.9853C22.0991 18.042 22.1332 18.0875 22.1899 18.1102"
        fill="white"
      />
      <path
        d="M22.9054 17.1903C22.9054 17.1903 22.8032 17.2016 22.7805 17.2584C22.7578 17.3152 22.7805 17.3606 22.8373 17.3833C22.8941 17.406 22.9395 17.3833 22.9622 17.3265C22.9736 17.2698 22.9622 17.2243 22.8941 17.2016"
        fill="white"
      />
      <path
        d="M23.0417 15.8161C23.0417 15.8161 22.9395 15.8274 22.9168 15.8842C22.8941 15.941 22.9168 15.9864 22.9736 16.0091C23.0304 16.0319 23.0758 16.0091 23.0985 15.9524C23.1099 15.8956 23.0985 15.8501 23.0304 15.8274"
        fill="white"
      />
      <path
        d="M20.2706 18.0988C20.2706 18.0988 20.3728 18.0988 20.3955 18.0307C20.4182 17.9739 20.3955 17.9285 20.3387 17.9058C20.282 17.883 20.2365 17.9058 20.2138 17.9625C20.2025 18.0193 20.2252 18.0648 20.282 18.0875"
        fill="white"
      />
      <path
        d="M19.8163 18.2919C19.8163 18.2919 19.9185 18.2805 19.9412 18.2238C19.964 18.167 19.9412 18.1215 19.8845 18.0988C19.8277 18.0761 19.7823 18.0988 19.7595 18.1556C19.7482 18.2124 19.7709 18.2578 19.8277 18.2805"
        fill="white"
      />
      <path
        d="M20.8498 17.5196C20.8498 17.5196 20.7476 17.531 20.7249 17.5878C20.7135 17.6445 20.7362 17.69 20.793 17.7127C20.8498 17.7354 20.8952 17.7127 20.918 17.6559C20.9293 17.5991 20.918 17.5537 20.8612 17.531"
        fill="white"
      />
      <path
        d="M19.1235 19.2345C19.1235 19.2345 19.2258 19.2232 19.2485 19.1664C19.2712 19.1096 19.2485 19.0642 19.1917 19.0415C19.1917 19.0415 19.0895 19.0415 19.0668 19.0982C19.044 19.155 19.0781 19.2005 19.1349 19.2232"
        fill="white"
      />
      <path
        d="M17.3064 17.9398C17.3064 17.9398 17.3064 17.8376 17.2496 17.8149C17.2042 17.7922 17.1474 17.8149 17.1247 17.8717C17.102 17.9285 17.1247 17.9739 17.1815 17.9966C17.2383 18.0193 17.2837 17.9966 17.3064 17.9398Z"
        fill="white"
      />
      <path
        d="M17.261 18.5872C17.261 18.5872 17.1588 18.5985 17.1361 18.6553C17.1247 18.7121 17.1474 18.7575 17.2042 18.7802C17.261 18.803 17.3064 18.7802 17.3291 18.7235C17.3405 18.6667 17.3291 18.6212 17.261 18.5985V18.5872Z"
        fill="white"
      />
      <path
        d="M17.6358 18.0534C17.6358 18.0534 17.738 18.042 17.7607 17.9853C17.7721 17.9285 17.7493 17.883 17.6926 17.8603C17.6358 17.8376 17.5903 17.8603 17.5676 17.9171C17.5563 17.9739 17.579 18.0193 17.6358 18.042"
        fill="white"
      />
      <path
        d="M19.646 17.2016C19.646 17.2016 19.6346 17.0994 19.5778 17.0767C19.521 17.0653 19.4756 17.0881 19.4529 17.1448C19.4302 17.2016 19.4643 17.2471 19.521 17.2698C19.5778 17.2811 19.6233 17.2584 19.646 17.2016Z"
        fill="white"
      />
      <path
        d="M19.2485 17.4855C19.2485 17.4855 19.2598 17.5878 19.3166 17.6105C19.3734 17.6218 19.4188 17.5991 19.4415 17.5423C19.4643 17.4855 19.4302 17.4401 19.3734 17.4174C19.3166 17.406 19.2712 17.4288 19.2485 17.4855Z"
        fill="white"
      />
      <path
        d="M18.7715 17.6673C18.7715 17.6673 18.8737 17.6559 18.8964 17.5991C18.9191 17.5423 18.8964 17.4969 18.8396 17.4742C18.7828 17.4515 18.7374 17.4742 18.7147 17.531C18.7033 17.5878 18.7261 17.6332 18.7828 17.6559"
        fill="white"
      />
      <path
        d="M18.8964 16.6906C18.8964 16.6906 18.885 16.5883 18.8283 16.5656C18.7715 16.5543 18.726 16.577 18.7033 16.6338C18.6806 16.6906 18.7147 16.736 18.7715 16.7587C18.8283 16.7701 18.8737 16.7473 18.8964 16.6906Z"
        fill="white"
      />
      <path
        d="M17.7607 17.406C17.7607 17.406 17.7493 17.3038 17.6926 17.2811C17.6358 17.2698 17.5903 17.2925 17.5676 17.3493C17.5449 17.406 17.5676 17.4515 17.6244 17.4742C17.6812 17.4855 17.7266 17.4628 17.7493 17.406"
        fill="white"
      />
      <path
        d="M18.1809 17.7695C18.1809 17.7695 18.1923 17.8717 18.2491 17.8944C18.3058 17.9058 18.3513 17.8944 18.374 17.8376C18.374 17.8376 18.374 17.724 18.3058 17.7127C18.2491 17.7013 18.2036 17.724 18.1809 17.7808"
        fill="white"
      />
      <path
        d="M19.1463 17.1676C19.1463 17.1676 19.1349 17.0653 19.0781 17.0426C19.0213 17.0313 18.9759 17.054 18.9532 17.1108C18.9305 17.1676 18.9645 17.213 19.0213 17.2357C19.0781 17.2471 19.1235 17.2243 19.1463 17.1676Z"
        fill="white"
      />
      <path
        d="M18.3513 17.1335C18.3513 17.1335 18.3626 17.2357 18.4194 17.2584C18.4762 17.2698 18.5216 17.2471 18.5443 17.1903C18.5557 17.1335 18.533 17.0881 18.4762 17.0653C18.4194 17.054 18.374 17.0767 18.3513 17.1335Z"
        fill="white"
      />
      <path
        d="M20.3615 17.5764C20.3615 17.5764 20.4637 17.565 20.4864 17.5083C20.5091 17.4515 20.4864 17.406 20.4296 17.3833C20.3728 17.3606 20.3274 17.3833 20.3047 17.4401C20.282 17.4969 20.316 17.5423 20.3728 17.565"
        fill="white"
      />
      <path
        d="M19.4302 18.0534C19.4302 18.0534 19.5324 18.042 19.5551 17.9853C19.5778 17.9285 19.5551 17.883 19.4983 17.8603C19.4983 17.8603 19.3961 17.8603 19.3734 17.9171C19.362 17.9739 19.3848 18.0193 19.4415 18.042"
        fill="white"
      />
      <path
        d="M20.0094 17.6445C20.0094 17.6445 20.0094 17.5423 19.9526 17.5196C19.9526 17.5196 19.8504 17.531 19.8277 17.5878C19.805 17.6445 19.839 17.69 19.8958 17.7127C19.9526 17.724 19.998 17.7013 20.0207 17.6445"
        fill="white"
      />
      <path
        d="M21.3609 20.8245C21.3609 20.8245 21.3495 20.7223 21.2927 20.6996C21.2359 20.6882 21.1905 20.6996 21.1678 20.7677C21.1451 20.8245 21.1678 20.8699 21.2359 20.8926C21.2927 20.904 21.3382 20.8813 21.3609 20.8245Z"
        fill="white"
      />
      <path
        d="M20.793 18.1897C20.793 18.1897 20.8952 18.1783 20.918 18.1215C20.9407 18.0648 20.918 18.0193 20.8612 17.9966C20.8612 17.9966 20.759 17.9966 20.7362 18.0534C20.7249 18.1102 20.7476 18.1556 20.8044 18.1783"
        fill="white"
      />
      <path
        d="M21.27 18.3033C21.27 18.3033 21.1678 18.3146 21.1451 18.3714C21.1224 18.4282 21.1451 18.4736 21.2019 18.4963C21.2587 18.519 21.3041 18.4963 21.3268 18.4395C21.3495 18.3828 21.3154 18.3373 21.2587 18.3146"
        fill="white"
      />
      <path
        d="M22.0991 19.7229C22.0991 19.7229 21.9969 19.7342 21.9742 19.791C21.9514 19.8478 21.9742 19.8932 22.0309 19.9159C22.0877 19.9387 22.1332 19.9159 22.1559 19.8592C22.1672 19.8024 22.1559 19.7569 22.0877 19.7342"
        fill="white"
      />
      <path
        d="M20.7249 19.3595C20.7249 19.3595 20.7362 19.4617 20.793 19.4844C20.8498 19.4957 20.8952 19.473 20.918 19.4162C20.9407 19.3595 20.918 19.314 20.8612 19.2913C20.8612 19.2913 20.759 19.3027 20.7362 19.3595"
        fill="white"
      />
      <path
        d="M19.7255 20.5406C19.7255 20.5406 19.8277 20.5292 19.8504 20.4724C19.8731 20.4157 19.8504 20.3702 19.7936 20.3475C19.7368 20.3248 19.6914 20.3475 19.6687 20.4043C19.646 20.4611 19.68 20.5065 19.7368 20.5292"
        fill="white"
      />
      <path
        d="M16.4546 17.0767C16.4546 17.0767 16.3524 17.0767 16.3297 17.1448C16.3184 17.2016 16.3297 17.247 16.3865 17.2698C16.3865 17.2698 16.4887 17.2698 16.5114 17.213C16.5341 17.1562 16.5114 17.1108 16.4546 17.0881"
        fill="white"
      />
      <path
        d="M16.0799 16.4748C16.0799 16.4748 15.9777 16.4861 15.9549 16.5429C15.9436 16.5997 15.9663 16.6451 16.0231 16.6678C16.0799 16.6906 16.1253 16.6678 16.148 16.6111C16.1594 16.5543 16.1367 16.5088 16.0799 16.4861"
        fill="white"
      />
      <path
        d="M16.1253 16.0432C16.1253 16.0432 16.1139 15.941 16.0572 15.9183C16.0004 15.9069 15.9549 15.9296 15.9322 15.9864C15.9095 16.0432 15.9322 16.0886 15.989 16.1113C16.0458 16.1227 16.0912 16.1 16.1139 16.0432"
        fill="white"
      />
      <path
        d="M16.6136 18.1102C16.6136 18.1102 16.5114 18.1215 16.4887 18.1783C16.4774 18.2351 16.5001 18.2805 16.5569 18.3033C16.6136 18.3146 16.6591 18.2919 16.6818 18.2351C16.7045 18.1783 16.6818 18.1329 16.625 18.1102"
        fill="white"
      />
      <path
        d="M14.4104 16.4521C14.4104 16.4521 14.3082 16.4634 14.2855 16.5202C14.2741 16.577 14.2968 16.6224 14.3536 16.6451C14.4104 16.6678 14.4558 16.6451 14.4785 16.5883C14.4899 16.5316 14.4785 16.4861 14.4104 16.4634"
        fill="white"
      />
      <path
        d="M15.2281 16.0773C15.2281 16.0773 15.2394 16.1795 15.2962 16.2022C15.353 16.2136 15.3984 16.1908 15.4212 16.1341C15.4439 16.0773 15.4212 16.0319 15.3644 16.0091C15.3076 15.9978 15.2622 16.0205 15.2394 16.0773"
        fill="white"
      />
      <path
        d="M16.4887 17.6445C16.4887 17.6445 16.5001 17.7468 16.5569 17.7695C16.6136 17.7808 16.6591 17.7581 16.6818 17.7013C16.6818 17.7013 16.6704 17.5991 16.6136 17.5764C16.5569 17.565 16.5114 17.5878 16.4887 17.6445Z"
        fill="white"
      />
      <path
        d="M15.9549 17.6786C15.9549 17.6786 15.9663 17.7808 16.0231 17.8035C16.0799 17.8149 16.1253 17.7922 16.148 17.7354C16.1707 17.6786 16.148 17.6332 16.0912 17.6105C16.0912 17.6105 15.989 17.6218 15.9663 17.6786"
        fill="white"
      />
      <path
        d="M18.0787 16.8268C18.0787 16.8268 18.0787 16.9291 18.1468 16.9518C18.1923 16.9631 18.2491 16.9518 18.2718 16.895C18.2831 16.8382 18.2718 16.7814 18.2036 16.7701C18.1468 16.7587 18.1014 16.7814 18.0787 16.8382"
        fill="white"
      />
      <path
        d="M17.7948 18.6326C17.7948 18.6326 17.897 18.6212 17.9197 18.5645C17.9424 18.5077 17.9083 18.4622 17.8516 18.4395C17.8516 18.4395 17.7493 18.4509 17.7266 18.5077C17.7153 18.5645 17.738 18.6099 17.7948 18.6326Z"
        fill="white"
      />
      <path
        d="M16.6136 18.837C16.6136 18.837 16.5114 18.8484 16.4887 18.9052C16.4774 18.962 16.5001 19.0074 16.5569 19.0301C16.6136 19.0415 16.6591 19.0187 16.6818 18.962C16.7045 18.9052 16.6818 18.8597 16.625 18.837"
        fill="white"
      />
      <path
        d="M15.6142 17.6105C15.6142 17.6105 15.6029 17.5083 15.5461 17.4855C15.4893 17.4742 15.4439 17.4855 15.4212 17.5537C15.3984 17.6105 15.4212 17.6559 15.4779 17.6786C15.5347 17.69 15.5802 17.6786 15.6029 17.6105H15.6142Z"
        fill="white"
      />
      <path
        d="M14.6943 15.1574C14.6943 15.1574 14.5921 15.1687 14.5694 15.2255C14.558 15.2823 14.5807 15.3277 14.6375 15.3504C14.6943 15.3731 14.7397 15.3504 14.7625 15.2936C14.7738 15.2369 14.7511 15.1914 14.6943 15.1687V15.1574Z"
        fill="white"
      />
      <path
        d="M16.1594 14.5895C16.1594 14.5895 16.0572 14.6009 16.0344 14.6577C16.0231 14.7144 16.0458 14.7599 16.1026 14.7826C16.1594 14.8053 16.2048 14.7826 16.2275 14.7258C16.2389 14.669 16.2162 14.6236 16.1594 14.6009"
        fill="white"
      />
      <path
        d="M22.6896 15.6911C22.7124 15.5889 22.6442 15.4981 22.5534 15.4754C22.4511 15.4526 22.3603 15.5208 22.3376 15.6116C22.3149 15.7139 22.383 15.8047 22.4739 15.8274C22.5761 15.8501 22.6669 15.782 22.6896 15.6911Z"
        fill="white"
      />
      <path
        d="M17.9311 19.4276C17.9538 19.3254 17.8856 19.2345 17.7948 19.2118C17.6926 19.1891 17.6017 19.2572 17.579 19.3481C17.5563 19.4503 17.6244 19.5412 17.7153 19.5639C17.8175 19.5866 17.9083 19.5185 17.9311 19.4276Z"
        fill="white"
      />
      <path
        d="M22.2921 18.485C22.1899 18.4623 22.0991 18.5304 22.0764 18.6212C22.0537 18.7235 22.1218 18.8143 22.2127 18.837C22.3149 18.8597 22.4057 18.7916 22.4284 18.7007C22.4511 18.5985 22.383 18.5077 22.2921 18.485Z"
        fill="white"
      />
      <path
        d="M21.7584 17.9285C21.6675 17.9058 21.5653 17.9739 21.5426 18.0648C21.5199 18.167 21.588 18.2578 21.6789 18.2805C21.7811 18.3032 21.8719 18.2351 21.8947 18.1443C21.9174 18.042 21.8492 17.9512 21.7584 17.9285Z"
        fill="white"
      />
      <path
        d="M20.0435 18.5417C20.0207 18.644 20.0889 18.7348 20.1797 18.7575C20.2706 18.7802 20.3728 18.7121 20.3842 18.6212C20.4069 18.519 20.3387 18.4282 20.2479 18.4055C20.1457 18.3827 20.0548 18.4509 20.0321 18.5417"
        fill="white"
      />
      <path
        d="M20.5205 16.895C20.4182 16.8723 20.3274 16.9404 20.3047 17.0313C20.282 17.1335 20.3501 17.2243 20.441 17.247C20.5432 17.2698 20.634 17.2016 20.6454 17.1108C20.6681 17.0086 20.6 16.9177 20.5091 16.895"
        fill="white"
      />
      <path
        d="M17.2383 16.2136C17.1361 16.1908 17.0452 16.259 17.0225 16.3498C16.9998 16.4521 17.0679 16.5429 17.1588 16.5656C17.261 16.5883 17.3519 16.5202 17.3746 16.4293C17.3973 16.3271 17.3291 16.2363 17.2383 16.2136Z"
        fill="white"
      />
      <path
        d="M17.2837 17.1676C17.3064 17.0653 17.2383 16.9745 17.1474 16.9518C17.0452 16.9291 16.9544 16.9972 16.9316 17.0881C16.9089 17.1903 16.9771 17.2811 17.0679 17.3038C17.1701 17.3265 17.261 17.2584 17.2837 17.1676Z"
        fill="white"
      />
      <path
        d="M23.6663 19.1437C23.6891 19.0415 23.6209 18.9506 23.5301 18.9279C23.4278 18.9052 23.337 18.9733 23.3143 19.0642C23.2916 19.1664 23.3597 19.2572 23.4506 19.28C23.5528 19.3027 23.6436 19.2345 23.6663 19.1437Z"
        fill="white"
      />
      <path
        d="M21.5767 16.3839C21.4858 16.3612 21.3836 16.4293 21.3609 16.5202C21.3382 16.6224 21.4063 16.7133 21.4972 16.736C21.5994 16.7587 21.6902 16.6906 21.7129 16.5997C21.7357 16.4975 21.6675 16.4066 21.5767 16.3839Z"
        fill="white"
      />
      <path
        d="M22.9509 16.4066C22.8486 16.3839 22.7578 16.4521 22.7351 16.5429C22.7124 16.6451 22.7805 16.736 22.8714 16.7587C22.9736 16.7814 23.0644 16.7133 23.0758 16.6224C23.0985 16.5202 23.0304 16.4293 22.9395 16.4066"
        fill="white"
      />
      <path
        d="M22.7237 17.724C22.6329 17.7013 22.5306 17.7695 22.5079 17.8603C22.4852 17.9625 22.5534 18.0534 22.6442 18.0761C22.7464 18.0988 22.8373 18.0307 22.8486 17.9398C22.8714 17.8376 22.8032 17.7468 22.7124 17.724"
        fill="white"
      />
      <path
        d="M19.169 18.485C19.1463 18.5872 19.2144 18.678 19.3053 18.7007C19.4075 18.7235 19.4983 18.6553 19.521 18.5645C19.5438 18.4736 19.4756 18.3714 19.3848 18.3487C19.2825 18.326 19.1917 18.3941 19.169 18.485Z"
        fill="white"
      />
      <path
        d="M18.726 17.9625C18.7033 18.0648 18.7715 18.1556 18.8623 18.1783C18.9532 18.201 19.0554 18.1329 19.0668 18.042C19.0895 17.9398 19.0213 17.849 18.9305 17.8263C18.8283 17.8035 18.7374 17.8717 18.7147 17.9625"
        fill="white"
      />
      <path
        d="M18.2718 18.6667C18.2491 18.7689 18.3172 18.8597 18.4081 18.8825C18.4989 18.9052 18.6011 18.837 18.6238 18.7462C18.6465 18.644 18.5784 18.5531 18.4876 18.5304C18.3853 18.5077 18.2945 18.5758 18.2718 18.6667Z"
        fill="white"
      />
      <path
        d="M16.5682 3.34607C16.5682 3.34607 16.5569 3.24386 16.5001 3.22115C16.4433 3.19843 16.3979 3.22115 16.3751 3.27793C16.3524 3.33472 16.3751 3.38015 16.4319 3.40286C16.4319 3.40286 16.5341 3.3915 16.5569 3.33472"
        fill="white"
      />
      <path
        d="M20.6795 2.0968C20.6795 2.0968 20.5772 2.0968 20.5545 2.15359C20.5318 2.21037 20.5545 2.2558 20.6113 2.27852C20.6681 2.30123 20.7249 2.27852 20.7362 2.22173C20.7476 2.16495 20.7362 2.10816 20.6795 2.0968Z"
        fill="white"
      />
      <path
        d="M20.1797 1.86966C20.1797 1.86966 20.0775 1.88102 20.0548 1.93781C20.0321 1.99459 20.0548 2.04002 20.1116 2.06273C20.1684 2.07409 20.2138 2.05138 20.2365 1.99459C20.2592 1.93781 20.2365 1.89238 20.1797 1.86966Z"
        fill="white"
      />
      <path
        d="M20.157 3.72086C20.157 3.72086 20.157 3.82307 20.2252 3.84578C20.282 3.8685 20.3274 3.84578 20.3501 3.789C20.3728 3.73221 20.3501 3.68678 20.282 3.66407C20.2252 3.65271 20.1797 3.67543 20.157 3.73221V3.72086Z"
        fill="white"
      />
      <path
        d="M19.4188 3.99342C19.4188 3.99342 19.521 3.98207 19.5438 3.92528C19.5665 3.8685 19.5438 3.82307 19.487 3.80035C19.4302 3.77764 19.3848 3.80035 19.362 3.8685C19.3507 3.92528 19.3734 3.97071 19.4302 3.99342"
        fill="white"
      />
      <path
        d="M19.1008 4.81113C19.1008 4.81113 19.0895 4.70892 19.0327 4.6862C18.9759 4.66349 18.9305 4.6862 18.9078 4.74299C18.885 4.79977 18.9191 4.8452 18.9759 4.86791C19.0327 4.89063 19.0781 4.86791 19.1008 4.81113Z"
        fill="white"
      />
      <path
        d="M20.0662 3.25522C20.0662 3.25522 20.1684 3.24386 20.1911 3.18708C20.2025 3.13029 20.1797 3.08486 20.123 3.06215C20.0662 3.03944 20.0208 3.07351 19.998 3.13029C19.9867 3.18708 19.998 3.2325 20.0548 3.25522"
        fill="white"
      />
      <path
        d="M19.5438 5.87869C19.5438 5.87869 19.646 5.87869 19.6687 5.81054C19.6914 5.75376 19.6687 5.70833 19.6119 5.68562C19.6119 5.68562 19.5097 5.68562 19.487 5.75376C19.4756 5.81054 19.4983 5.85597 19.5551 5.87869"
        fill="white"
      />
      <path
        d="M19.4302 4.43635C19.4302 4.43635 19.4188 4.33413 19.362 4.31142C19.3053 4.28871 19.2598 4.31142 19.2371 4.36821C19.2144 4.42499 19.2485 4.47042 19.3053 4.49313C19.362 4.51585 19.4075 4.48178 19.4302 4.42499"
        fill="white"
      />
      <path
        d="M18.8964 4.12971C18.8964 4.12971 18.9986 4.12971 19.0213 4.06157C19.0327 4.00478 19.01 3.95935 18.9532 3.93664C18.8964 3.91392 18.851 3.948 18.8283 4.00478C18.8055 4.06157 18.8283 4.10699 18.885 4.12971"
        fill="white"
      />
      <path
        d="M21.3268 1.5176C21.3268 1.5176 21.3268 1.61981 21.3836 1.64252C21.3836 1.64252 21.4858 1.64252 21.5085 1.58574C21.5312 1.52895 21.4972 1.48353 21.4404 1.46081C21.3836 1.4381 21.3382 1.47217 21.3154 1.52895"
        fill="white"
      />
      <path
        d="M19.7709 2.11952C19.7709 2.11952 19.7595 2.0173 19.7028 1.99459C19.646 1.97188 19.6005 1.99459 19.5778 2.05138C19.5551 2.10816 19.5892 2.15359 19.646 2.1763C19.7028 2.19902 19.7482 2.16495 19.7709 2.10816"
        fill="white"
      />
      <path
        d="M19.6119 3.50507C19.6119 3.50507 19.7141 3.50507 19.7368 3.44829C19.7595 3.3915 19.7368 3.34607 19.68 3.32336C19.6233 3.30065 19.5778 3.32336 19.5551 3.38015C19.5324 3.43693 19.5551 3.48236 19.6119 3.50507Z"
        fill="white"
      />
      <path
        d="M19.169 3.471C19.169 3.471 19.1576 3.36879 19.1008 3.34607C19.044 3.32336 18.9986 3.34607 18.9759 3.40286C18.9532 3.45964 18.9873 3.50507 19.044 3.52779C19.1008 3.5505 19.1463 3.52779 19.169 3.45964V3.471Z"
        fill="white"
      />
      <path
        d="M19.1917 2.76687C19.1917 2.76687 19.0895 2.77822 19.0668 2.83501C19.044 2.89179 19.0668 2.93722 19.1235 2.95994C19.1803 2.98265 19.2258 2.94858 19.2485 2.89179C19.2712 2.83501 19.2371 2.78958 19.1803 2.76687"
        fill="white"
      />
      <path
        d="M18.4648 3.69814C18.4648 3.69814 18.5671 3.68678 18.5898 3.63C18.6125 3.57321 18.5898 3.52779 18.533 3.50507C18.4762 3.48236 18.4308 3.50507 18.4081 3.57321C18.3967 3.63 18.4194 3.67543 18.4762 3.69814"
        fill="white"
      />
      <path
        d="M18.3286 5.07234C18.3286 5.07234 18.4308 5.07234 18.4535 5.0042C18.4648 4.94741 18.4421 4.90198 18.3853 4.87927C18.3286 4.85656 18.2718 4.89063 18.2604 4.94741C18.2377 5.0042 18.2604 5.04962 18.3172 5.07234"
        fill="white"
      />
      <path
        d="M20.9747 2.85772C20.9747 2.85772 20.9861 2.95994 21.0429 2.98265C21.0997 3.00536 21.1451 2.98265 21.1678 2.92586C21.1905 2.86908 21.1564 2.82365 21.0997 2.80094C21.0429 2.77822 20.9974 2.81229 20.9747 2.86908"
        fill="white"
      />
      <path
        d="M21.4972 2.77822C21.4972 2.77822 21.5994 2.76687 21.6221 2.71008C21.6448 2.6533 21.6221 2.60787 21.5653 2.58516C21.5085 2.56244 21.4631 2.58516 21.4404 2.6533C21.429 2.71008 21.4517 2.75551 21.5085 2.77822"
        fill="white"
      />
      <path
        d="M20.5205 3.36879C20.5205 3.36879 20.6227 3.36879 20.6454 3.30065C20.6681 3.24386 20.6454 3.19843 20.5886 3.17572C20.5318 3.15301 20.475 3.18708 20.4637 3.24386C20.4523 3.30065 20.475 3.34607 20.5318 3.36879"
        fill="white"
      />
      <path
        d="M22.0991 1.74474C22.0991 1.74474 22.2013 1.73338 22.224 1.67659C22.2354 1.61981 22.2127 1.57438 22.1559 1.55167C22.0991 1.54031 22.0537 1.56302 22.0309 1.60845C22.0082 1.66524 22.0309 1.71067 22.0877 1.73338"
        fill="white"
      />
      <path
        d="M24.2001 2.89179C24.2001 2.89179 24.0979 2.90315 24.0752 2.95994C24.0525 3.01672 24.0752 3.06215 24.132 3.08486C24.1888 3.10758 24.2342 3.07351 24.2569 3.01672C24.2796 2.95994 24.2456 2.91451 24.1888 2.89179H24.2001Z"
        fill="white"
      />
      <path
        d="M24.0525 2.1763C24.0525 2.1763 24.0525 2.27852 24.1093 2.30123C24.1093 2.30123 24.2115 2.30123 24.2342 2.24444C24.2569 2.18766 24.2228 2.14223 24.1661 2.11952C24.1093 2.0968 24.0638 2.13087 24.0411 2.18766"
        fill="white"
      />
      <path
        d="M23.7458 2.83501C23.7458 2.83501 23.6436 2.84637 23.6209 2.90315C23.5982 2.95994 23.6209 3.00536 23.6777 3.02808C23.7345 3.05079 23.7799 3.01672 23.8026 2.95994C23.8253 2.90315 23.7913 2.85772 23.7345 2.83501"
        fill="white"
      />
      <path
        d="M21.7811 3.81171C21.7811 3.81171 21.8833 3.81171 21.906 3.74357C21.9174 3.68678 21.8947 3.64136 21.8379 3.61864C21.7811 3.59593 21.7357 3.63 21.7129 3.68678C21.7016 3.74357 21.7129 3.789 21.7697 3.81171"
        fill="white"
      />
      <path
        d="M22.0537 3.28929C22.0537 3.28929 21.9514 3.30065 21.9287 3.35743C21.906 3.41422 21.9287 3.45964 21.9855 3.48236C22.0423 3.50507 22.0877 3.471 22.1104 3.41422C22.1332 3.35743 22.0991 3.312 22.0423 3.28929"
        fill="white"
      />
      <path
        d="M22.5988 3.22115C22.5988 3.22115 22.4966 3.2325 22.4739 3.28929C22.4511 3.34607 22.4739 3.3915 22.5306 3.41422C22.5874 3.43693 22.6329 3.40286 22.6556 3.34607C22.6669 3.28929 22.6442 3.24386 22.5874 3.22115"
        fill="white"
      />
      <path
        d="M22.5988 4.12971C22.5988 4.12971 22.4966 4.14107 22.4739 4.19785C22.4511 4.25463 22.4739 4.30006 22.5306 4.32278C22.5874 4.34549 22.6329 4.32278 22.6556 4.25463C22.6669 4.19785 22.6556 4.15242 22.5874 4.12971"
        fill="white"
      />
      <path
        d="M23.6209 3.48236C23.6209 3.48236 23.6323 3.58457 23.6891 3.60729C23.7458 3.63 23.7913 3.60729 23.814 3.5505C23.8367 3.49372 23.814 3.44829 23.7458 3.42557C23.6891 3.41422 23.6436 3.43693 23.6209 3.49372"
        fill="white"
      />
      <path
        d="M23.1326 2.99401C23.1326 2.99401 23.0304 3.00536 23.0076 3.06215C22.9849 3.11893 23.0076 3.16436 23.0644 3.18708C23.1212 3.20979 23.1666 3.18708 23.1894 3.13029C23.2007 3.07351 23.1894 3.02808 23.1326 3.00536"
        fill="white"
      />
      <path
        d="M22.2921 3.84578C22.2921 3.84578 22.3944 3.83443 22.4171 3.77764C22.4398 3.72086 22.4171 3.67543 22.3603 3.65271C22.3035 3.63 22.2581 3.66407 22.2354 3.72086C22.2127 3.77764 22.2467 3.82307 22.3035 3.84578"
        fill="white"
      />
      <path
        d="M22.9509 3.63C22.9509 3.63 22.8486 3.64136 22.8259 3.69814C22.8032 3.75493 22.8259 3.80035 22.8827 3.82307C22.9395 3.84578 22.9849 3.82307 23.0076 3.75493C23.019 3.69814 23.0076 3.65271 22.9395 3.63H22.9509Z"
        fill="white"
      />
      <path
        d="M20.952 3.50507C20.952 3.50507 21.0542 3.49372 21.077 3.43693C21.0997 3.38015 21.077 3.33472 21.0202 3.312C20.9634 3.28929 20.918 3.32336 20.8952 3.38015C20.8725 3.43693 20.9066 3.48236 20.9634 3.50507"
        fill="white"
      />
      <path
        d="M21.8265 2.89179C21.8265 2.89179 21.8379 2.99401 21.8947 3.01672C21.9514 3.03944 21.9969 3.01672 22.0196 2.95994C22.0423 2.90315 22.0196 2.85772 21.9514 2.83501C21.8947 2.82365 21.8492 2.83501 21.8265 2.90315V2.89179Z"
        fill="white"
      />
      <path
        d="M21.3609 3.24386C21.3609 3.24386 21.3722 3.34607 21.429 3.36879C21.4858 3.3915 21.5312 3.36879 21.5539 3.30065C21.5767 3.24386 21.5426 3.19843 21.4858 3.17572C21.429 3.16436 21.3836 3.18708 21.3609 3.24386Z"
        fill="white"
      />
      <path
        d="M20.0094 0.0638998C20.0094 0.0638998 20.0094 0.166113 20.0662 0.188827C20.0662 0.188827 20.1684 0.188827 20.1911 0.132042C20.2138 0.0752568 20.1797 0.0298287 20.123 0.00711473C20.0662 -0.0155993 20.0208 0.0184717 19.998 0.0752568"
        fill="white"
      />
      <path
        d="M20.5205 2.88044C20.5205 2.88044 20.6227 2.88044 20.6454 2.8123C20.6567 2.75551 20.634 2.71008 20.5772 2.68737C20.5205 2.66465 20.475 2.68737 20.4523 2.74415C20.441 2.80094 20.4523 2.84637 20.5091 2.86908"
        fill="white"
      />
      <path
        d="M20.0321 2.46023C20.0321 2.46023 20.0435 2.56244 20.1002 2.58515C20.157 2.60787 20.2025 2.58516 20.2252 2.52837C20.2479 2.47158 20.2252 2.42616 20.157 2.40344C20.1002 2.39209 20.0548 2.40344 20.0321 2.47158"
        fill="white"
      />
      <path
        d="M19.2144 1.0406C19.2144 1.0406 19.2144 1.14282 19.2712 1.16553C19.2712 1.16553 19.3734 1.16553 19.3961 1.10874C19.4188 1.05196 19.3848 1.00653 19.328 0.983817C19.2712 0.961103 19.2258 0.995174 19.203 1.05196"
        fill="white"
      />
      <path
        d="M20.4523 1.47217C20.4523 1.47217 20.4637 1.57438 20.5205 1.5971C20.5772 1.61981 20.6227 1.5971 20.6454 1.54031C20.6681 1.48353 20.6454 1.42674 20.5772 1.41538C20.5205 1.40403 20.475 1.42674 20.4523 1.48353"
        fill="white"
      />
      <path
        d="M21.588 0.529537C21.588 0.529537 21.6902 0.51818 21.7129 0.461395C21.7357 0.40461 21.7129 0.359182 21.6562 0.336468C21.5994 0.313754 21.5539 0.336468 21.5312 0.40461C21.5199 0.461395 21.5312 0.506823 21.588 0.529537Z"
        fill="white"
      />
      <path
        d="M24.9156 3.81171C24.9156 3.81171 25.0178 3.80035 25.0405 3.74357C25.0633 3.68678 25.0405 3.64136 24.9838 3.61864C24.927 3.59593 24.8815 3.61864 24.8588 3.68678C24.8475 3.74357 24.8702 3.789 24.927 3.81171"
        fill="white"
      />
      <path
        d="M25.2904 4.42499C25.2904 4.42499 25.3926 4.41363 25.4153 4.35685C25.438 4.30006 25.4153 4.25463 25.3585 4.23192C25.3018 4.20921 25.2563 4.24328 25.2336 4.30006C25.2109 4.35685 25.245 4.40228 25.3018 4.42499"
        fill="white"
      />
      <path
        d="M25.3699 4.78841C25.3699 4.78841 25.2677 4.79977 25.245 4.85656C25.2223 4.91334 25.245 4.95877 25.3018 4.98148C25.3585 5.0042 25.404 4.97013 25.4267 4.91334C25.4494 4.85656 25.4267 4.81113 25.3585 4.78841"
        fill="white"
      />
      <path
        d="M24.6998 2.6533C24.6998 2.6533 24.7112 2.75551 24.768 2.77822C24.8248 2.80094 24.8702 2.77822 24.8929 2.72144C24.9156 2.66465 24.8929 2.61923 24.8248 2.59651C24.768 2.58516 24.7225 2.59651 24.6998 2.66465V2.6533Z"
        fill="white"
      />
      <path
        d="M27.0167 4.25463C27.0167 4.25463 26.9145 4.26599 26.8917 4.32278C26.869 4.37956 26.8917 4.42499 26.9599 4.4477C27.0167 4.47042 27.0621 4.43635 27.0848 4.37956C27.0962 4.32278 27.0735 4.27735 27.0167 4.25463Z"
        fill="white"
      />
      <path
        d="M26.0854 4.6862C26.0854 4.6862 25.9832 4.69756 25.9605 4.75434C25.9377 4.81113 25.9605 4.85656 26.0172 4.87927C26.074 4.90198 26.1195 4.86791 26.1422 4.81113C26.1649 4.75434 26.1422 4.70892 26.074 4.6862H26.0854Z"
        fill="white"
      />
      <path
        d="M24.6998 3.18708C24.6998 3.18708 24.7112 3.28929 24.768 3.312C24.8248 3.33472 24.8702 3.312 24.8929 3.25522C24.9156 3.19843 24.8929 3.14165 24.8248 3.13029C24.768 3.11893 24.7225 3.13029 24.6998 3.19843V3.18708Z"
        fill="white"
      />
      <path
        d="M25.3585 3.08486C25.3585 3.08486 25.2563 3.08486 25.2336 3.15301C25.2223 3.20979 25.245 3.25522 25.3018 3.27793C25.3585 3.30065 25.4153 3.26658 25.4267 3.20979C25.438 3.15301 25.4153 3.10758 25.3585 3.08486Z"
        fill="white"
      />
      <path
        d="M23.2916 4.06157C23.2916 4.06157 23.2916 3.95935 23.2348 3.93664C23.178 3.92528 23.1326 3.93664 23.1099 3.99342C23.0871 4.05021 23.1099 4.09564 23.1666 4.11835C23.2234 4.14107 23.2689 4.11835 23.2916 4.06157Z"
        fill="white"
      />
      <path
        d="M23.6436 2.38073C23.6436 2.38073 23.6323 2.27852 23.5755 2.2558C23.5187 2.23309 23.4733 2.2558 23.4506 2.32394C23.4279 2.38073 23.4619 2.42616 23.5187 2.44887C23.5755 2.47159 23.6209 2.43751 23.6436 2.38073Z"
        fill="white"
      />
      <path
        d="M24.8248 1.86966C24.8248 1.86966 24.7225 1.86966 24.6998 1.93781C24.6771 1.99459 24.6998 2.04002 24.7566 2.06273C24.8134 2.08545 24.8702 2.06273 24.8815 1.99459C24.8929 1.93781 24.8702 1.89238 24.8134 1.86966"
        fill="white"
      />
      <path
        d="M25.8923 3.22115C25.8923 3.22115 25.7901 3.2325 25.7674 3.28929C25.7674 3.28929 25.7674 3.3915 25.8242 3.41422C25.881 3.43693 25.9264 3.41422 25.9491 3.34607C25.9605 3.28929 25.9377 3.24386 25.881 3.22115"
        fill="white"
      />
      <path
        d="M26.7441 5.54933C26.7441 5.54933 26.6419 5.56069 26.6192 5.61748C26.5965 5.67426 26.6192 5.71969 26.676 5.7424C26.7327 5.76512 26.7782 5.73105 26.8009 5.67426C26.8236 5.61748 26.8009 5.57205 26.7327 5.54933"
        fill="white"
      />
      <path
        d="M25.2109 6.2989C25.2109 6.2989 25.3131 6.28754 25.3358 6.23075C25.3472 6.17397 25.3245 6.12854 25.2677 6.10583C25.2109 6.08311 25.1655 6.11718 25.1428 6.17397C25.1314 6.23075 25.1428 6.27618 25.1995 6.2989"
        fill="white"
      />
      <path
        d="M18.8964 5.06098C18.8055 5.03827 18.7033 5.10641 18.6806 5.19727C18.6579 5.28812 18.726 5.39034 18.8169 5.41305C18.9191 5.43576 19.01 5.36762 19.0327 5.27677C19.0554 5.17455 18.9873 5.0837 18.8964 5.06098Z"
        fill="white"
      />
      <path
        d="M23.6436 1.32453C23.5528 1.30181 23.4506 1.36996 23.4278 1.46081C23.4051 1.56302 23.4733 1.65388 23.5641 1.67659C23.6663 1.69931 23.7572 1.63117 23.7799 1.54031C23.8026 1.4381 23.7345 1.34724 23.6436 1.32453Z"
        fill="white"
      />
      <path
        d="M18.9305 2.19902C18.9078 2.28987 18.9759 2.39209 19.0668 2.4148C19.1576 2.43751 19.2598 2.36937 19.2825 2.27852C19.3053 2.1763 19.2371 2.08545 19.1463 2.06273C19.044 2.04002 18.9532 2.10816 18.9305 2.19902Z"
        fill="white"
      />
      <path
        d="M19.4756 2.74415C19.4529 2.83501 19.521 2.93722 19.6119 2.95994C19.7141 2.98265 19.805 2.91451 19.8277 2.82365C19.8504 2.72144 19.7823 2.63058 19.6914 2.61923C19.5892 2.59651 19.4983 2.66465 19.4756 2.75551"
        fill="white"
      />
      <path
        d="M21.3268 2.34666C21.3495 2.24444 21.2814 2.15359 21.1905 2.13087C21.0883 2.10816 20.9974 2.1763 20.9747 2.26716C20.952 2.36937 21.0202 2.46023 21.111 2.48294C21.2132 2.50566 21.3041 2.43751 21.3268 2.34666Z"
        fill="white"
      />
      <path
        d="M20.7135 3.77764C20.6908 3.8685 20.759 3.97071 20.8498 3.99342C20.9407 4.01614 21.0429 3.948 21.0542 3.85714C21.077 3.75493 21.0088 3.66407 20.918 3.65271C20.8157 3.63 20.7249 3.69814 20.7022 3.789"
        fill="white"
      />
      <path
        d="M24.132 4.6862C24.2342 4.70892 24.3251 4.64077 24.3478 4.54992C24.3705 4.4477 24.3023 4.35685 24.2115 4.33413C24.1206 4.31142 24.0184 4.37956 24.0071 4.47042C23.9843 4.57263 24.0525 4.66349 24.1433 4.6862"
        fill="white"
      />
      <path
        d="M24.0866 3.72086C24.0638 3.81171 24.132 3.91392 24.2228 3.93664C24.3251 3.95935 24.4159 3.89121 24.4386 3.80035C24.4613 3.7095 24.3932 3.60729 24.3023 3.58457C24.2001 3.56186 24.1093 3.63 24.0866 3.72086Z"
        fill="white"
      />
      <path
        d="M17.8402 1.96052C17.9424 1.98323 18.0333 1.91509 18.056 1.82424C18.0787 1.72202 18.0106 1.63117 17.9197 1.60845C17.8175 1.58574 17.7266 1.65388 17.7039 1.74474C17.6812 1.84695 17.7493 1.93781 17.8402 1.96052Z"
        fill="white"
      />
      <path
        d="M19.7936 4.50449C19.8958 4.5272 19.9867 4.45906 20.0094 4.36821C20.0321 4.26599 19.964 4.17514 19.8731 4.15242C19.7823 4.12971 19.68 4.19785 19.6573 4.28871C19.6346 4.39092 19.7028 4.48178 19.7936 4.50449Z"
        fill="white"
      />
      <path
        d="M18.2831 4.27735C18.2604 4.36821 18.3286 4.47042 18.4194 4.49313C18.5216 4.51585 18.6125 4.4477 18.6352 4.35685C18.6579 4.25463 18.5898 4.16378 18.4989 4.14107C18.3967 4.11835 18.3058 4.18649 18.2831 4.27735Z"
        fill="white"
      />
      <path
        d="M18.7147 2.8123C18.6238 2.78958 18.5216 2.85772 18.4989 2.94858C18.4762 3.05079 18.5443 3.14165 18.6352 3.16436C18.7374 3.18708 18.8283 3.11893 18.8396 3.02808C18.8623 2.92587 18.7942 2.83501 18.7033 2.8123"
        fill="white"
      />
      <path
        d="M21.8492 2.3353C21.8265 2.42616 21.8947 2.52837 21.9855 2.55108C22.0764 2.5738 22.1786 2.50566 22.2013 2.4148C22.224 2.31259 22.1559 2.22173 22.065 2.19902C21.9628 2.1763 21.8719 2.24444 21.8492 2.3353Z"
        fill="white"
      />
      <path
        d="M22.5079 2.71008C22.4057 2.68737 22.3149 2.75551 22.2921 2.84637C22.2694 2.94858 22.3376 3.03944 22.4284 3.06215C22.5306 3.08486 22.6215 3.01672 22.6329 2.92587C22.6556 2.82365 22.5874 2.7328 22.4966 2.71008"
        fill="white"
      />
      <path
        d="M22.9622 2.0173C22.86 1.99459 22.7691 2.06273 22.7464 2.15359C22.7237 2.2558 22.7919 2.34666 22.8827 2.36937C22.9736 2.39209 23.0758 2.32394 23.0985 2.23309C23.1212 2.13087 23.0531 2.04002 22.9622 2.0173Z"
        fill="white"
      />
      <path
        d="M15.9322 9.41072C15.9322 9.41072 15.9436 9.51293 16.0004 9.53564C16.0572 9.55836 16.1026 9.53564 16.1253 9.47886C16.148 9.42207 16.1253 9.37664 16.0685 9.35393C16.0117 9.33122 15.9663 9.36529 15.9436 9.42207"
        fill="white"
      />
      <path
        d="M14.047 8.94508C14.047 8.94508 13.9447 8.95644 13.922 9.01322C13.9107 9.07001 13.9334 9.11543 13.9902 9.13815C14.047 9.16086 14.0924 9.13815 14.1151 9.07001C14.1265 9.01322 14.1037 8.96779 14.047 8.94508Z"
        fill="white"
      />
      <path
        d="M16.2389 11.1256C16.3411 11.1483 16.4319 11.0802 16.4546 10.9893C16.4774 10.8871 16.4092 10.7963 16.3184 10.7736C16.2162 10.7508 16.1253 10.819 16.1026 10.9098C16.0799 11.0121 16.148 11.1029 16.2389 11.1256Z"
        fill="white"
      />
      <path
        d="M7.65297 26.8097C7.65297 27.2866 7.51668 27.6501 7.23276 27.9226C7.00562 28.1384 6.74441 28.252 6.41505 28.2747C6.38098 28.2747 6.35827 28.2747 6.3242 28.2747C5.56328 28.2747 5.09764 27.6955 5.01814 27.0936C4.95 26.5825 5.06357 26.1737 5.37021 25.8443C5.62006 25.5717 5.93806 25.4241 6.31284 25.4241C6.38098 25.4241 6.44912 25.4241 6.51727 25.4355C7.17597 25.5149 7.67568 26.1055 7.65297 26.7983V26.8097ZM6.33555 24.7313C5.109 24.7313 4.21179 25.6172 4.20044 26.8437C4.20044 27.4229 4.40486 27.9681 4.80236 28.3542C5.19985 28.7517 5.74499 28.9675 6.33555 28.9675C6.92612 28.9675 7.47125 28.7517 7.86875 28.3542C8.25489 27.9681 8.47067 27.4343 8.47067 26.8437C8.47067 26.2645 8.25489 25.7194 7.86875 25.3219C7.48261 24.9357 6.93748 24.72 6.34691 24.72"
        fill="white"
      />
      <path
        d="M36.9427 25.5149V24.8222H34.3987V28.8766H36.9427V28.1725H35.1937V27.1731H36.6474V26.4576H35.1937V25.5149H36.9427Z"
        fill="white"
      />
      <path
        d="M19.1803 27.1958L19.6914 25.9352C19.8617 26.3554 20.0321 26.7756 20.2025 27.1958H19.1803ZM19.3848 24.7995L17.6812 28.888H18.5103L18.533 28.8312C18.6579 28.5132 18.7828 28.2179 18.9078 27.9113H20.475L20.8725 28.888H21.7129L20.0094 24.7995H19.3848Z"
        fill="white"
      />
      <path
        d="M2.14482 26.6734C2.03125 26.6166 1.90632 26.5712 1.79275 26.5371C1.73597 26.5144 1.67918 26.4916 1.6224 26.4689C1.56561 26.4462 1.49747 26.4235 1.44068 26.4008C1.29304 26.344 1.1454 26.2872 0.997761 26.2191C0.861477 26.1509 0.816049 26.0374 0.804692 25.9579C0.781978 25.8216 0.838763 25.6739 0.929619 25.5944C1.08862 25.4468 1.3044 25.4014 1.57697 25.4354C1.83818 25.4695 2.08803 25.5717 2.38332 25.7648C2.40603 25.7762 2.42874 25.7875 2.45146 25.8102L2.62181 25.9011V25.1175L2.57639 25.0834C2.04261 24.7427 1.52018 24.6405 0.952333 24.7881C0.39584 24.9357 0.0664868 25.2878 0.00970172 25.8329C-0.0357263 26.2077 0.0778437 26.5144 0.361769 26.7529C0.600266 26.9573 0.872834 27.0481 1.12269 27.139L1.19083 27.1617C1.39526 27.2299 1.59968 27.3094 1.80411 27.3889C1.96311 27.457 2.05396 27.5933 2.04261 27.775C2.04261 27.9567 1.95175 28.093 1.78139 28.1838C1.6224 28.2633 1.44068 28.2861 1.19083 28.252C0.929619 28.2179 0.668408 28.1157 0.373126 27.9226C0.339055 27.8999 0.259556 27.8545 0.259556 27.8545L0.111915 27.775V28.5473L0.134629 28.5813C0.134629 28.5813 0.191414 28.6268 0.214128 28.6381C0.600266 28.8539 0.986404 28.9675 1.37254 28.9675C1.57697 28.9675 1.79275 28.9334 1.99718 28.8766C2.5196 28.7176 2.82624 28.3201 2.84895 27.7863C2.87167 27.2639 2.64453 26.9005 2.14482 26.6734Z"
        fill="white"
      />
      <path
        d="M11.9686 25.4355C11.9686 25.4355 12.0368 25.4355 12.0708 25.4355C12.4456 25.4355 12.7977 25.5377 13.1498 25.7534C13.1838 25.7762 13.2633 25.8102 13.2633 25.8102L13.3996 25.8784V25.1288L13.3542 25.0947C13.3542 25.0947 13.2633 25.038 13.2179 25.0152C12.7977 24.7995 12.3207 24.6972 11.821 24.7427C11.4235 24.7767 11.0828 24.879 10.7989 25.072C10.1061 25.5149 9.78808 26.1737 9.83351 27.0254C9.8903 27.8999 10.4695 28.6268 11.3213 28.8766C11.5484 28.9448 11.7869 28.9788 12.0368 28.9788C12.1503 28.9788 12.2639 28.9788 12.3775 28.9561C12.7295 28.9107 13.0248 28.8085 13.2747 28.6608C13.3088 28.6381 13.3542 28.5927 13.3655 28.5813L13.3996 28.5473V28.5018C13.3996 28.5018 13.3996 28.1611 13.3996 27.9794V27.8091L13.2179 27.9113C13.2179 27.9113 13.1725 27.934 13.1611 27.9454C12.809 28.1611 12.457 28.2633 12.0822 28.2633C12.0368 28.2633 11.9913 28.2633 11.9573 28.2633C11.5825 28.2406 11.2645 28.093 11.026 27.8431C10.6966 27.4797 10.5717 27.0595 10.6739 26.5371C10.7875 25.9124 11.3099 25.4695 11.98 25.4355"
        fill="white"
      />
      <path
        d="M29.5152 24.8108V25.5263H30.7077V28.888H31.5254V25.5149H32.7179L32.7065 24.8108H29.5152Z"
        fill="white"
      />
      <path
        d="M24.1206 28.2179V24.8449H23.337V28.9107H25.8582L25.8469 28.2179H24.1206Z"
        fill="white"
      />
      <path d="M16.1026 24.8108H15.3189V28.888H16.1026V24.8108Z" fill="white" />
      <path d="M28.0161 24.8108H27.2324V28.888H28.0161V24.8108Z" fill="white" />
      <path
        d="M40.77 26.6734C40.6564 26.6166 40.5315 26.5712 40.4179 26.5371C40.3611 26.5144 40.3044 26.4916 40.2476 26.4689C40.1908 26.4462 40.134 26.4235 40.0659 26.4008C39.9182 26.344 39.7592 26.2872 39.6229 26.2191C39.4867 26.1509 39.4412 26.0374 39.4299 25.9579C39.4072 25.8216 39.4639 25.6739 39.5548 25.5944C39.7138 25.4468 39.9182 25.4014 40.2022 25.4354C40.452 25.4695 40.7132 25.5717 41.0085 25.7648C41.0312 25.7762 41.0539 25.7875 41.0766 25.8102L41.247 25.9011V25.1175L41.2016 25.0834C40.6678 24.7427 40.134 24.6405 39.5775 24.7881C39.021 24.9357 38.6917 25.2878 38.6349 25.8329C38.5895 26.2077 38.703 26.5144 38.987 26.7529C39.2254 26.9573 39.498 27.0481 39.7479 27.139L39.816 27.1617C40.0204 27.2299 40.2249 27.3094 40.4293 27.3889C40.5883 27.457 40.6791 27.5933 40.6678 27.775C40.6678 27.9567 40.5769 28.093 40.4066 28.1838C40.2476 28.2633 40.0659 28.2861 39.816 28.252C39.5548 28.2179 39.2936 28.1157 38.9983 27.9226C38.9642 27.8999 38.8847 27.8545 38.8847 27.8545L38.7371 27.775V28.5473L38.7598 28.5813C38.7598 28.5813 38.8166 28.6268 38.8393 28.6381C39.2254 28.8539 39.6116 28.9675 39.9977 28.9675C40.2022 28.9675 40.4179 28.9334 40.6224 28.8766C41.1448 28.7176 41.4514 28.3201 41.4741 27.7863C41.4968 27.2639 41.2697 26.9005 40.77 26.6734Z"
        fill="white"
      />
      <path
        d="M329.09 12.0001C329.09 12.568 328.897 12.6588 328.329 12.6588V10.3533C328.897 10.3533 329.09 10.4442 329.09 11.0121V12.0001ZM330.555 12.0001V11.0121C330.555 9.44479 329.999 8.88829 328.341 8.88829H326.876V16.6792H328.341V14.1239C329.999 14.0898 330.555 13.5447 330.555 12.0001Z"
        fill="white"
      />
      <path
        d="M334.11 10.3533V8.89965H331.328V16.6792H334.11V15.2255H332.793V13.522H334.11V12.0569H332.793V10.3533H334.11Z"
        fill="white"
      />
      <path
        d="M335.814 12.8292C335.814 10.9439 337.074 9.34257 339.323 9.34257C341.572 9.34257 342.81 10.9439 342.81 12.8292C342.81 14.7144 341.549 16.3385 339.323 16.3385C337.097 16.3385 335.814 14.7372 335.814 12.8292ZM343.502 12.8292C343.502 10.4101 341.742 8.6498 339.323 8.6498C336.904 8.6498 335.121 10.3647 335.121 12.8292C335.121 15.2936 336.847 17.0313 339.323 17.0313C341.799 17.0313 343.502 15.305 343.502 12.8292Z"
        fill="white"
      />
      <path
        d="M337.79 12.8292C337.79 12.4544 337.903 11.2278 339.323 11.2278C340.743 11.2278 340.845 12.4544 340.845 12.8292C340.845 13.204 340.731 14.4532 339.323 14.4532C337.915 14.4532 337.79 13.2153 337.79 12.8292ZM341.538 12.8292C341.538 11.648 340.845 10.5351 339.323 10.5351C337.801 10.5351 337.097 11.648 337.097 12.8292C337.097 14.0103 337.79 15.146 339.323 15.146C340.856 15.146 341.538 14.033 341.538 12.8292Z"
        fill="white"
      />
      <path
        d="M345.797 10.3533C346.364 10.3533 346.557 10.4556 346.557 11.0121V11.9888C346.557 12.5566 346.364 12.6475 345.797 12.6475V10.342V10.3533ZM345.797 14.1239C347.466 14.0898 348.011 13.5447 348.011 12.0001V11.0234C348.011 9.45614 347.455 8.89965 345.797 8.89965H344.331V16.6906H345.797V14.1239Z"
        fill="white"
      />
      <path
        d="M348.806 8.89965V16.6792H351.577V15.2255H350.26V8.89965H348.806Z"
        fill="white"
      />
      <path
        d="M355.132 10.3533V8.89965H352.361V16.6792H355.132V15.2255H353.815V13.522H355.132V12.0569H353.815V10.3533H355.132Z"
        fill="white"
      />
      <path
        d="M341.753 19.6547H338.335V21.7217H341.208V22.812H338.335V24.913H337.165V18.4395H341.753V19.6547ZM337.165 17.7581H336.472V25.6058H339.016V23.5048H341.89V21.0403H339.016V20.3589H342.435V17.7581H337.154H337.165Z"
        fill="white"
      />
      <path d="M328.295 17.7808H326.83V25.5717H328.295V17.7808Z" fill="white" />
      <path
        d="M333.906 22.5394L330.533 17.7808H329.079V25.5717H330.533V20.8245L333.906 25.5717H335.371V17.7808H333.906V22.5394Z"
        fill="white"
      />
      <path
        d="M344.899 17.7808H343.434V25.5717H346.205V24.1067H344.899V17.7808Z"
        fill="white"
      />
      <path
        d="M349.033 23.3571C349.033 24.0158 348.988 24.2089 348.658 24.2089C348.329 24.2089 348.284 24.0158 348.284 23.3571V17.7922H346.819V23.4593C346.819 25.0266 347.466 25.674 348.647 25.674C349.828 25.674 350.476 25.0266 350.476 23.4593V17.7922H349.011V23.3571H349.033Z"
        fill="white"
      />
      <path
        d="M351.271 25.5717H354.042V24.1067H352.736V22.4031H354.042V20.9494H352.736V19.2459H354.042V17.7808H351.271V25.5717Z"
        fill="white"
      />
      <path
        d="M357.54 21.6309L356.29 17.7808H354.825V25.5717H356.29V21.7217L357.54 25.5717H359.005V17.7808H357.54V21.6309Z"
        fill="white"
      />
      <path
        d="M361.617 17.69C360.436 17.69 359.788 18.3373 359.788 19.9046V23.448C359.788 25.0152 360.436 25.6626 361.617 25.6626C362.798 25.6626 363.445 25.0152 363.445 23.448V22.4031H361.98V23.3458C361.98 24.0045 361.935 24.2089 361.605 24.2089C361.276 24.2089 361.231 24.0158 361.231 23.3458V19.9954C361.231 19.3367 361.276 19.1323 361.605 19.1323C361.935 19.1323 361.98 19.3254 361.98 19.9954V20.9381H363.445V19.8932C363.445 18.326 362.798 17.6786 361.617 17.6786"
        fill="white"
      />
      <path
        d="M364.229 17.7808V25.5717H367V24.1067H365.683V22.4145H367V20.9494H365.683V19.2459H367V17.7808H364.229Z"
        fill="white"
      />
      <path
        d="M141.075 16.3953C140.189 16.1908 139.78 15.9296 139.633 15.4526C139.508 14.9984 139.61 14.6917 139.928 14.51C140.485 14.192 141.166 14.4305 142.006 15.1914C142.461 15.5889 142.756 15.9751 142.926 16.3498V14.6122L142.71 14.5327C142.427 14.4078 141.757 14.2488 141.075 14.2488C140.405 14.2488 139.849 14.3624 139.394 14.6122C138.951 14.8621 138.701 15.2596 138.645 15.8161C138.508 16.9972 139.065 17.7013 140.666 18.1443C141.45 18.3714 141.984 18.6099 142.256 18.8597C142.529 19.1096 142.642 19.4049 142.597 19.7115C142.506 20.3475 141.643 20.2907 140.939 20.0636C140.45 19.9046 139.996 19.5866 139.565 19.1437C139.144 18.6894 138.792 18.2124 138.52 17.7127V19.8592C139.178 20.2453 140.008 20.4384 141.007 20.4497C142.029 20.4724 143.063 20.1658 143.324 19.0301C143.63 17.6445 142.972 16.8496 141.064 16.3726"
        fill="white"
      />
      <path
        d="M147.844 20.2112C147.446 20.1772 147.094 19.8819 146.788 19.3254C146.47 18.7689 146.254 18.0988 146.129 17.3152C146.004 16.5429 145.981 15.8956 146.084 15.3618C146.186 14.828 146.379 14.5214 146.663 14.4419C146.947 14.3624 147.219 14.4646 147.48 14.7144C147.742 14.9643 147.946 15.2936 148.105 15.7139C148.264 16.1227 148.389 16.5656 148.469 17.0653C148.775 19.0528 148.582 20.2907 147.844 20.2226M147.48 14.2942C146.561 14.2942 145.8 14.5668 145.22 15.1119C144.63 15.6571 144.335 16.3839 144.335 17.2698C144.3 17.9853 144.539 18.7121 144.993 19.3254C145.459 19.9273 146.254 20.3475 147.231 20.3816C148.253 20.3816 149.093 20.0068 149.581 19.4276C150.07 18.8257 150.331 18.0988 150.331 17.3379C150.331 16.5543 150.127 15.8161 149.684 15.2482C149.241 14.669 148.469 14.2942 147.492 14.2942"
        fill="white"
      />
      <path
        d="M153.488 14.5782C154.158 14.2942 154.692 14.4305 155.169 14.828C155.771 15.3731 156.237 15.9637 156.555 16.6111V14.7826L156.509 14.7258C156.418 14.6577 156.157 14.5327 155.839 14.4419C155.317 14.2942 154.783 14.2488 154.249 14.2829C153.443 14.351 152.727 14.6577 152.16 15.1914C151.592 15.7025 151.251 16.5429 151.285 17.5196C151.33 19.2686 152.966 20.904 155.158 20.3589C155.703 20.2226 156.157 19.8705 156.373 19.5866C156.487 19.439 156.577 19.28 156.657 19.0982C156.816 18.7462 156.827 18.6553 156.839 18.5872L156.793 18.6894C156.68 18.9392 156.203 19.5412 155.203 19.5071C154.59 19.4957 154.067 19.2686 153.613 18.8597C153.17 18.4395 152.898 17.8717 152.761 17.1562C152.671 16.6111 152.682 16.0773 152.807 15.5776C152.932 15.0779 153.17 14.7372 153.5 14.5782"
        fill="white"
      />
      <path
        d="M162.074 17.9398L162.483 16.9972C162.733 16.3726 162.915 16.0773 163.028 16.0773C163.085 16.0773 163.153 16.1681 163.21 16.3385L163.392 16.7928L163.857 17.9398H162.074ZM169.842 20.2226C169.32 20.2226 169.059 19.8478 169.059 19.1323V15.4981C169.059 14.828 169.343 14.5554 169.615 14.4532C169.82 14.3624 169.888 14.3737 169.979 14.3737H166.594C166.685 14.3737 166.753 14.3737 166.958 14.4532C167.06 14.4987 167.151 14.5441 167.219 14.6236C167.367 14.7599 167.503 15.0779 167.503 15.4981V19.0869C167.48 19.7456 167.23 20.0522 166.958 20.1885C166.878 20.1544 166.81 20.1317 166.696 20.0636C166.515 19.9387 166.378 19.7797 166.288 19.5639L163.732 13.8854L163.71 13.8059L161.313 19.3027C161.12 19.7456 160.802 20.0636 160.484 20.2112C160.2 20.0977 159.871 19.8137 159.871 19.1777V15.4981C159.871 14.828 160.2 14.5554 160.496 14.4532C160.723 14.3624 160.802 14.3737 160.893 14.3737H157.316C157.406 14.3737 157.486 14.3737 157.713 14.4532C157.827 14.4987 157.929 14.5441 157.997 14.6236C158.167 14.7599 158.326 15.0779 158.326 15.4981V19.1777C158.326 20.2567 157.406 20.3021 157.304 20.3021H162.21C161.892 20.2794 161.677 20.1544 161.552 19.9159C161.427 19.6888 161.427 19.4276 161.54 19.1664L162.017 18.0534H163.903L164.391 19.2572C164.766 20.1317 163.721 20.3021 163.721 20.3021H172.102L172.568 17.9853C172.398 18.3714 172 18.8484 171.387 19.4049C170.774 19.95 170.251 20.2226 169.831 20.2339"
        fill="white"
      />
      <path
        d="M147.106 28.093C146.492 28.0703 145.97 27.8545 145.516 27.4456C145.073 27.0254 144.8 26.4576 144.664 25.7421C144.573 25.1856 144.573 24.6632 144.709 24.1635C144.834 23.6638 145.073 23.323 145.402 23.164C146.072 22.8801 146.606 23.0164 147.083 23.4139C147.685 23.959 148.15 24.5496 148.468 25.197V23.3685L148.423 23.3117C148.332 23.2435 148.071 23.1186 147.753 23.0278C147.231 22.8801 146.697 22.8347 146.163 22.8688C145.357 22.9369 144.641 23.2435 144.073 23.7773C143.505 24.2997 143.165 25.1288 143.199 26.1055C143.244 27.8545 144.891 29.4899 147.072 28.9448C147.617 28.8085 148.071 28.4564 148.287 28.1725C148.4 28.0248 148.491 27.8658 148.571 27.6841C148.73 27.3321 148.741 27.2412 148.752 27.1731L148.707 27.2753C148.593 27.5251 148.116 28.1271 147.117 28.093"
        fill="white"
      />
      <path
        d="M152.443 28.8085C151.921 28.8085 151.66 28.4337 151.66 27.7182V24.084C151.66 23.4139 151.944 23.1413 152.216 23.0391C152.421 22.9483 152.489 22.9596 152.58 22.9596H149.195C149.286 22.9596 149.354 22.9596 149.559 23.0391C149.661 23.0846 149.74 23.13 149.82 23.2095C149.968 23.3458 150.104 23.6638 150.104 24.084V27.6728C150.081 28.3883 149.786 28.7063 149.491 28.8085C149.263 28.8993 149.184 28.888 149.093 28.888H154.715L155.18 26.5711C155.01 26.9573 154.613 27.4343 153.999 27.9908C153.386 28.5473 152.864 28.8085 152.443 28.8198"
        fill="white"
      />
      <path
        d="M160.643 24.3792V26.0942C160.643 27.8204 160.325 28.5813 159.428 28.6608C158.417 28.7517 157.838 28.1384 157.838 26.5825V24.1521C157.838 23.4366 158.133 23.1413 158.417 23.0391C158.644 22.9483 158.713 22.9596 158.837 22.9596H155.215C155.305 22.9596 155.385 22.9596 155.601 23.0391C155.714 23.0846 155.805 23.1413 155.885 23.2208C156.044 23.3685 156.191 23.6978 156.191 24.1521V26.3099C156.191 27.2866 156.384 27.9794 156.77 28.411C157.157 28.8312 157.815 29.047 158.747 29.047C159.303 29.047 159.746 28.8653 160.019 28.6608C160.302 28.4451 160.507 28.0248 160.598 27.7409C160.643 27.5933 160.677 27.4116 160.711 27.1844C160.734 26.9573 160.757 26.7869 160.757 26.6734C160.757 26.5598 160.757 26.3894 160.757 26.1964V24.4474C160.757 23.6183 161.063 23.2322 161.336 23.0732C161.552 22.9483 161.643 22.9369 161.733 22.9256H159.576C159.735 22.9256 160.632 23.0732 160.632 24.3452"
        fill="white"
      />
      <path
        d="M165.89 28.411C165.708 28.6722 165.493 28.7971 165.266 28.8085C165.05 28.8085 164.857 28.729 164.686 28.5927C164.516 28.4451 164.436 28.1725 164.436 27.7864V25.9011C164.913 25.8784 165.231 25.8897 165.413 25.9238C165.652 25.9806 165.833 26.1169 165.97 26.344C166.106 26.5598 166.174 26.9346 166.174 27.457C166.174 27.8431 166.095 28.1611 165.902 28.4223M164.425 23.0164C164.97 22.9937 165.345 23.0959 165.549 23.3117C165.765 23.5275 165.867 23.8795 165.879 24.3792C165.879 25.072 165.731 25.4922 165.402 25.6626C165.254 25.7194 164.936 25.7534 164.436 25.7534V23.0051L164.425 23.0164ZM167.173 26.0714C166.81 25.867 166.492 25.7989 166.14 25.7989C166.878 25.7307 167.401 25.231 167.401 24.3906C167.401 23.4139 166.787 22.9369 165.549 22.9369L161.79 22.9596C161.858 22.9596 161.927 22.9596 162.176 23.0391C162.301 23.0846 162.415 23.13 162.506 23.1981C162.699 23.3344 162.892 23.6524 162.892 24.0613V27.7636C162.892 28.4337 162.54 28.6949 162.21 28.8085C161.972 28.888 161.881 28.888 161.79 28.888H165.777C167.128 28.888 167.798 28.3769 167.798 27.3434C167.798 26.7188 167.548 26.2645 167.185 26.0714"
        fill="white"
      />
      <path
        d="M151.705 8.61573V6.09447H152.534V5.26541H150.024V6.09447H150.853V8.61573H151.705Z"
        fill="white"
      />
      <path
        d="M154.079 5.26541V8.61573H154.942V7.33238H155.873V8.61573H156.725V5.26541H155.873V6.50332H154.942V5.26541H154.079Z"
        fill="white"
      />
      <path
        d="M160.62 6.07176V5.26541H158.497V8.61573H160.643V7.80938H159.348V7.32103H160.507V6.52604H159.348V6.07176H160.62Z"
        fill="white"
      />
      <path d="M160.768 10.501H149.979V11.2051H160.768V10.501Z" fill="white" />
      <path
        d="M210.875 7.95702L219.7 8.0933C219.211 7.69581 218.609 7.44595 217.973 7.38917L208.309 6.28754C208.309 6.28754 208.309 6.2989 208.309 6.31025C208.479 6.68503 208.729 7.02574 209.035 7.28696C209.535 7.71852 210.182 7.96838 210.864 7.96838"
        fill="white"
      />
      <path
        d="M219.189 16.1C219.972 16.1 220.71 15.6344 221.062 14.8961L221.744 13.4879C222.755 11.3868 221.403 8.86558 219.086 8.62708C219.064 8.62708 219.041 8.62708 219.03 8.62708L209.66 9.28579C209.66 9.28579 209.66 9.28579 209.66 9.29714C209.91 9.67193 210.217 9.96721 210.569 10.2057C211.227 10.6259 212.045 10.7963 212.851 10.6373L220.529 9.20629L211.466 11.773C211.466 11.773 211.943 12.5112 212.67 12.7383C213.351 12.9541 214.089 13.0677 214.782 12.8065L221.358 10.0581L214.237 14.0444C214.237 14.0444 214.771 14.828 215.736 15.0665C215.94 15.1233 216.145 15.146 216.349 15.1574C217.212 15.2028 218.042 14.8167 218.643 14.2034L221.823 11.1824L218.144 15.9069C218.144 15.9069 218.621 16.0886 219.177 16.0886"
        fill="white"
      />
      <path
        d="M225.514 8.0933L234.339 7.95702C235.02 7.95702 235.668 7.70716 236.167 7.2756C236.463 7.01439 236.724 6.68503 236.894 6.2989C236.894 6.2989 236.894 6.28754 236.894 6.27618L227.229 7.37781C226.593 7.4346 225.991 7.68445 225.503 8.08195"
        fill="white"
      />
      <path
        d="M233.76 11.7843L224.697 9.21765L232.374 10.6486C233.18 10.8076 233.987 10.6373 234.645 10.2171C234.998 9.97857 235.316 9.68328 235.565 9.3085V9.29714L226.196 8.63844C226.196 8.63844 226.162 8.63844 226.139 8.63844C223.811 8.87694 222.459 11.3982 223.482 13.4992L224.163 14.9075C224.515 15.6457 225.265 16.1113 226.037 16.1113C226.593 16.1113 227.07 15.9296 227.07 15.9296L223.379 11.2051L226.559 14.2261C227.173 14.828 227.99 15.2141 228.853 15.1801C229.058 15.1687 229.274 15.146 229.478 15.0892C230.455 14.8394 230.977 14.0671 230.977 14.0671L223.856 10.0808L230.432 12.8405C231.136 13.1017 231.863 12.9768 232.544 12.7724C233.271 12.5452 233.748 11.807 233.748 11.807"
        fill="white"
      />
      <path
        d="M200.847 22.2101H199.643V25.9352H200.847V23.1527H203.38V22.2214H200.847V22.2101Z"
        fill="white"
      />
      <path
        d="M199.643 21.1766C199.791 21.699 200.2 21.9943 200.847 22.1533V20.3929H203.834V19.4617H199.632V21.1766H199.643Z"
        fill="white"
      />
      <path
        d="M205.594 19.2005H204.447V25.9352H205.594V19.2005Z"
        fill="white"
      />
      <path
        d="M208.445 25.8557H208.422L207.423 21.54H206.196L207.343 25.9352H208.286L207.752 27.9454H208.99L210.671 21.54H209.433L208.445 25.8557Z"
        fill="white"
      />
      <path
        d="M212.386 19.6093H211.239V20.6996H212.386V19.6093Z"
        fill="white"
      />
      <path d="M212.386 21.54H211.239V25.9238H212.386V21.54Z" fill="white" />
      <path
        d="M215.963 21.4605C215.077 21.4605 214.532 22.0624 214.532 23.0846H214.521V21.5514H213.374V25.9352H214.521V23.5615C214.521 22.812 214.884 22.3691 215.486 22.3691C216.031 22.3691 216.349 22.7211 216.349 23.3117V25.9352H217.508V23.2549C217.508 22.1192 216.929 21.4605 215.963 21.4605Z"
        fill="white"
      />
      <path
        d="M220.483 25.0493C219.779 25.0493 219.37 24.5496 219.37 23.7092C219.37 22.8688 219.779 22.3577 220.483 22.3577C221.187 22.3577 221.596 22.9142 221.596 23.7092C221.596 24.5042 221.187 25.0493 220.483 25.0493ZM221.528 23.0505H221.517C221.517 22.0511 220.938 21.4605 219.949 21.4605C218.848 21.4605 218.201 22.3009 218.201 23.7205C218.201 25.1402 218.848 25.9806 219.949 25.9806C220.926 25.9806 221.517 25.3787 221.517 24.3906H221.528V26.0714C221.528 26.7302 221.142 27.1276 220.483 27.1276C219.904 27.1276 219.495 26.821 219.427 26.3099L218.337 26.4803C218.496 27.4684 219.291 28.0589 220.483 28.0589C221.869 28.0589 222.687 27.298 222.687 26.0147V21.5741H221.528V23.0732V23.0505Z"
        fill="white"
      />
      <path
        d="M224.742 19.4503V20.3929H229.728V19.4503H224.742Z"
        fill="white"
      />
      <path
        d="M226.639 21.3242V25.9238H227.843V20.4724C227.229 20.6201 226.82 20.8813 226.639 21.3242Z"
        fill="white"
      />
      <path d="M231.397 19.6093H230.25V20.6996H231.397V19.6093Z" fill="white" />
      <path d="M231.397 21.54H230.25V25.9238H231.397V21.54Z" fill="white" />
      <path
        d="M234.441 25.038C233.748 25.038 233.339 24.5382 233.339 23.6978C233.339 22.8574 233.748 22.3463 234.441 22.3463C235.134 22.3463 235.554 22.9028 235.554 23.6978C235.554 24.4928 235.145 25.038 234.441 25.038ZM235.486 23.0391H235.475C235.475 22.0397 234.884 21.4491 233.907 21.4491C232.806 21.4491 232.158 22.2896 232.158 23.7092C232.158 25.1288 232.806 25.9692 233.907 25.9692C234.884 25.9692 235.475 25.3787 235.475 24.3792H235.486V26.0601C235.486 26.7188 235.088 27.1163 234.43 27.1163C233.85 27.1163 233.442 26.8096 233.373 26.2986L232.283 26.4689C232.442 27.457 233.249 28.0476 234.43 28.0476C235.815 28.0476 236.633 27.2866 236.633 26.0033V21.5627H235.486V23.0618V23.0391Z"
        fill="white"
      />
      <path
        d="M238.495 23.4707C238.575 22.7325 238.95 22.3123 239.54 22.3123C240.131 22.3123 240.494 22.7325 240.562 23.4707H238.495ZM239.54 21.4491C238.177 21.4491 237.371 22.2896 237.371 23.7319C237.371 25.1742 238.177 26.0147 239.54 26.0147C240.721 26.0147 241.539 25.4014 241.687 24.3565H240.562C240.449 24.8335 240.074 25.1402 239.529 25.1402C238.904 25.1402 238.518 24.6632 238.473 23.8568H241.675V23.7319C241.675 22.2896 240.869 21.4491 239.529 21.4491"
        fill="white"
      />
      <path
        d="M243.572 23.3458H243.561V21.54H242.414V25.9238H243.561V23.9363C243.561 23.0164 243.958 22.4599 244.64 22.4599C244.855 22.4599 245.083 22.5167 245.253 22.6189L245.582 21.5854C245.401 21.4946 245.162 21.4491 244.912 21.4491C244.083 21.4491 243.583 22.1533 243.583 23.3458"
        fill="white"
      />
      <path
        d="M277.166 22.9028L275.928 26.185L274.69 22.9028H272.646V27.8772H274.088V24.72L275.235 27.9226H276.587L277.757 24.7086V27.9226H279.494V22.9028H277.166Z"
        fill="white"
      />
      <path
        d="M281.641 26.7869V25.9919H284.809V24.8449H281.641V24.0272H285.059V22.9028H279.88V27.9226H285.127V26.7869H281.641Z"
        fill="white"
      />
      <path
        d="M289.125 26.4803C288.921 26.662 288.58 26.7756 287.978 26.7756H287.206V24.084H288.023C288.353 24.084 288.773 24.118 289.08 24.3679C289.329 24.5723 289.511 24.9357 289.511 25.4127C289.511 25.8443 289.352 26.2532 289.114 26.4689M290.897 24.084C290.215 22.8688 288.784 22.8915 288.285 22.8915H285.491V27.9113H288.603C289.08 27.9113 289.568 27.8772 290.09 27.5933C290.658 27.298 290.885 26.8891 291.01 26.5939C291.158 26.2304 291.237 25.8443 291.237 25.4468C291.237 24.6972 290.999 24.2657 290.897 24.0726"
        fill="white"
      />
      <path
        d="M293.316 22.9028H291.555V27.9226H293.316V22.9028Z"
        fill="white"
      />
      <path
        d="M296.076 25.9919L296.643 24.2884L297.223 25.9919H296.076ZM297.631 22.9028H295.633L293.52 27.9226H295.451L295.712 27.1049H297.586L297.847 27.9226H299.767L297.631 22.9028Z"
        fill="white"
      />
      <path
        d="M290.965 14.9643C290.931 15.2482 290.794 15.5435 290.567 15.8501L290.431 16.0318H290.34V15.7706C290.34 15.5889 290.318 15.4299 290.283 15.305C290.249 15.1801 290.193 15.0665 290.124 14.9643C290.045 14.8621 289.965 14.7712 289.886 14.7031C289.806 14.6349 289.682 14.5554 289.534 14.4987C289.386 14.4305 289.227 14.3624 289.08 14.3169C288.932 14.2602 288.75 14.192 288.523 14.1239C288.455 14.1012 288.398 14.0898 288.364 14.0784C287.308 13.715 286.808 13.1358 286.865 12.3522C286.888 11.9433 287.103 11.5913 287.501 11.3187C287.898 11.0461 288.341 10.9326 288.841 10.9553C289.647 11.0007 290.193 11.1597 290.522 11.4209L290.567 11.4663V13.204H290.454L290.318 12.8632C290.136 12.443 290 12.1705 289.92 12.0342C289.647 11.6367 289.273 11.4323 288.762 11.4323C288.296 11.4323 287.967 11.5118 287.774 11.7162C287.615 11.8752 287.615 12.0569 287.774 12.2727C288.023 12.6247 288.58 12.9314 289.443 13.1812C289.568 13.2153 289.716 13.2721 289.875 13.3516C290.034 13.4311 290.215 13.556 290.397 13.7037C290.59 13.8627 290.738 14.033 290.851 14.2261C290.965 14.4305 291.01 14.6804 290.976 14.9756M288.216 16.0318L287.194 15.7479V14.1693H287.285L287.433 14.4192C287.705 14.8621 288.216 15.5208 288.216 15.5208V16.0318ZM286.274 11.807H284.31V15.8842H283.367V11.807H281.357V11.0461H286.263V11.807H286.274ZM291.112 8.46808C289.863 7.18474 288.126 6.38975 286.218 6.38975C284.31 6.38975 282.572 7.18474 281.323 8.46808C280.073 9.75143 279.29 11.5231 279.29 13.4765C279.29 17.3833 282.39 20.5633 286.218 20.5633C288.126 20.5633 289.863 19.7683 291.112 18.485C292.373 17.2016 293.145 15.4299 293.145 13.4765C293.145 11.5231 292.373 9.75143 291.112 8.46808Z"
        fill="white"
      />
    </svg>
  );
};

export default BrandsLogoNoBg;
