import React, { Component, useState } from "react";
import "./Globals.css";
import { useParams } from "react-router-dom";
import FontFaceObserver from "fontfaceobserver";
import ThompsonSpencerLoader from "./Shared/ThompsonSpencerLoader";
import GrandOpen from "./Shared/GrandOpen";
import { useGlobalContext } from "../context/GlobalContext";
import { checkIfThompsonSpencerUrl } from "../utils/general"

const Layout = ({ previewMode, children }) => {
  const {
    isPreviewMode,
    setIsPreviewMode,
    isLoadingContent,
    shouldDisplayComponents,
    setGreetings,
    isCustomFontLoaded,
    setIsCustomFontLoaded,
  } = useGlobalContext();

  const sfProFont = new FontFaceObserver("SFPro");
  const pxGroteskFont = new FontFaceObserver("Px Grotesk");
  const mierAFont = new FontFaceObserver("Mier A");
  const mierABoldFont = new FontFaceObserver("Mier A Bold");
  const poppinsFont = new FontFaceObserver("Poppins");
  const poppinsBoldFont = new FontFaceObserver("Poppins Bold");
  const montserratFont = new FontFaceObserver("Montserrat");
  const montserratBoldFont = new FontFaceObserver("Montserrat Bold");
  // const sfProFont = new FontFaceObserver("SF Pro");
  const sfProBoldFont = new FontFaceObserver("SF Pro Bold");

  Promise.all([
    // sfProFont.load(),
    pxGroteskFont.load(),
    mierAFont.load(),
    mierABoldFont.load(),
    poppinsFont.load(),
    poppinsBoldFont.load(),
    montserratFont.load(),
    montserratBoldFont.load(),
    sfProFont.load(),
    sfProBoldFont.load()
  ]).then(function () {
    setIsCustomFontLoaded(true);
  });

  return (
    <div id="layout-container">
      {checkIfThompsonSpencerUrl(!window.location.href) && (
        <div id="magnesium-sidebrand">
          <img
            className="social-club-sidebrand"
            src="https://storage.googleapis.com/quotes-assets/TSCstripImage.png"
            alt="social-club-sidebrand"
          />
          <img
            className="m-sidebrand"
            src="https://storage.googleapis.com/quotes-assets/MstripImage.png"
            alt="magnesium-sidebrand"
          />
          <img
            className="socialites-sidebrand"
            src="https://storage.googleapis.com/quotes-assets/SstripImage.png"
            alt="socialites-sidebrand"
          />
          <img
            className="flying-tiger-sidebrand"
            src="https://storage.googleapis.com/quotes-assets/FTstripImage.png"
            alt="flying-tiger-sidebrand"
          />
          <img
            className="media-x-sidebrand"
            src="https://storage.googleapis.com/quotes-assets/MXstripImage.png"
            alt="media-x-sidebrand"
          />
          <img
            className="people-of-influence-sidebrand"
            // src="https://storage.googleapis.com/quotes-assets/POIstripImage.png"
            src="https://storage.googleapis.com/quotes-assets/POI_Strip_Dark.png"
            alt="people-of-influence-sidebrand"
          />
        </div>
      )}
      {previewMode != 1 && isLoadingContent && !isCustomFontLoaded && (//!isPreviewMode
        <ThompsonSpencerLoader />
      )}
      {!isLoadingContent && !shouldDisplayComponents && <GrandOpen />}
      {children}
    </div>
  );
};

export default Layout;
