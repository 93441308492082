import React from "react";
import Lottie from 'react-lottie-player';
import SocialitesJson from '../../assets/animation/Socialites_PartyConfetti.json';
import MagnesiumJson from '../../assets/animation/Magnesium_PartyConfetti.json';
import FlyingTigerJson from '../../assets/animation/FlyingTiger_PartyConfetti.json';
import PeopleOfInfluenceJson from '../../assets/animation/PeopleOfInfluence_PartyConfetti.json';
import MediaXJson from '../../assets/animation/MediaX_PartyConfetti.json';
import PartyJson from '../../assets/animation/Party Confetti.json';

export const JobApproved = (props) => {
  const { user, organisationId } = props;

  const getJson = () => {
    if(organisationId === 1){
      return MagnesiumJson
    } else if(organisationId === 2){
      return SocialitesJson
    } else if(organisationId === 3){
      return PartyJson
    } else if(organisationId === 4){
      return FlyingTigerJson
    } else if(organisationId === 5){
      return PeopleOfInfluenceJson
    } else if(organisationId === 6){
      return MediaXJson
    } else {
      return PartyJson
    }
  }

  return (
    <React.Fragment>
      <div className="use-header-font job-approved-div">
        <div className="lottie-container">
          <Lottie
            animationData={getJson()}
            play
            className="lottie-player"
          />
        </div>
        <div className="job-approved-text-container">
          <div className="job-approved-div-top">
            <div className={"thankyou"} >Thank You</div>
          </div>
          <div className="job-approved-div-bottom">
            <div>{user.firstName} will be</div>
            <div>in touch shortly.</div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
