import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import "../src/assets/fonts/MierA-Bold.woff2";
import "../src/assets/fonts/MierA-Regular.woff";
import { GlobalContextProvider } from "./context/GlobalContext";
const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <GlobalContextProvider>
      <App />
    </GlobalContextProvider>
  </BrowserRouter>,
  rootElement
);

registerServiceWorker();
