import { Fade, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import "./thompsonSpencerLoader.scss";
import { useGlobalContext } from "../../context/GlobalContext";

const ThompsonSpencerLoader = (props) => {
  const {greetings} = useGlobalContext()
  const {setIsLoadingContent} = useGlobalContext();

  const [fadeTransition, setfadeTransition] = useState(true);

  useEffect(() => {
    if (greetings) {
      setTimeout(() => setfadeTransition(false), 2000);
    }
  }, [greetings]);

  useEffect(() => {
    if (!fadeTransition) {
      setTimeout(() => setIsLoadingContent(false), 500);
    }
  }, [fadeTransition]);

  return (
    <div className="thompson-spencer-loader-wrapper">
      <div>
          <div className="thompson-spencer-loader-text-container">
            {greetings ? (
              <Fade in={fadeTransition} timeout={{ enter: 1000, exit: 500 }}>
                <span>{greetings}</span>
              </Fade>
            ) : (
              <Fade in={fadeTransition} timeout={{ enter: 1000, exit: 500 }}>
                <span>Authenticating</span>
              </Fade>
            )}
          </div>
      </div>
      <div>
        <div className="thompson-spencer-loader-root">
          <div className="thompson-spencer-loader-logo">
            <img
              alt="TS-vector"
              src="https://storage.googleapis.com/quotes-assets/logo-vector/TS-vector.svg"
              width={150}
              height={150}
            />
          </div>
          <div className="thompson-spencer-loader">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThompsonSpencerLoader
