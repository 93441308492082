import React, { useEffect, useState } from "react";
import "./influencersList.scss";
import { Avatar, Button, Typography } from "@mui/material";
import { formatNumber } from "../../utils/quoteUtils";
import InstagramIcon from "../Icons/InstagramIcon";
import TikTokIcon from "../Icons/TikTokIcon";
import { internationalNumberFormat } from "../../utils/general";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";

const InfluencerDetails = ({ influencer, handleStatusChange }) => {
  const [currentProfile, setCurrentProfile] = useState(null);

  useEffect(() => {
    if (influencer?.socialMedia.length > 0) {
      setCurrentProfile(influencer?.socialMedia.find(Boolean));
    }
  }, []);

  const handleButtonAction = async (e, status) => {
    e.preventDefault();
    if (status === "discuss") {
      return alert("This feature is coming soon.");
    }

    await handleStatusChange(status, [influencer.scheduleItemId]);
  };

  const renderEngamentRate = () => {
    if (currentProfile?.engagementRate) {
      const rate = formatNumber(currentProfile?.engagementRate * 100, 1);
      return (
        <div className="talent-management-influencer-details-rate-container">
          <Typography
            className="talent-management-influencer-details-rate-total"
            variant="body2"
          >
            {rate || 0}%
          </Typography>
          <Typography
            variant="caption"
            className="talent-management-influencer-details-rate-caption"
          >
            Engagement Rate
          </Typography>
        </div>
      );
    }
    return (
      <Typography
        variant="caption"
        className="talent-management-influencer-details-rate-caption"
      >
        Engagement rate not available
      </Typography>
    );
  };

  return (
    <div className={`talent-management-influencer-details-root`}>
      <div className={`talent-management-influencer-details-wrapper`}>
        {currentProfile && (
          <div className={`talent-management-influencer-list-container `}>
            <div className="talent-management-influencer-details-avatar-container">
              <Avatar
                className="talent-management-influencer-details-avatar-img"
                src={currentProfile.avatar}
                sx={{ width: 72, height: 72 }}
              />
              <div>
                <Typography className="talent-management-influencer-details-profile-name">
                  {currentProfile.profileId}
                </Typography>
                {renderEngamentRate()}
              </div>
            </div>
            <div className="talent-management-influencer-details-platform-root">
              <div className="talent-management-influencer-details-platform-container">
                {currentProfile.platform === "INSTAGRAM" && (
                  <span
                    className="talent-management-influencer-details-platform-container-logo"
                    onClick={() =>
                      window.open(
                        `https://www.instagram.com/${currentProfile.profileId}/`
                      )
                    }
                  >
                    <InstagramIcon />
                  </span>
                )}
                {currentProfile.platform === "TIKTOK" && (
                  <span
                    className="talent-management-influencer-details-platform-container-logo"
                    onClick={() =>
                      window.open(
                        `https://www.tiktok.com/@${currentProfile.profileId}`
                      )
                    }
                  >
                    <TikTokIcon />
                  </span>
                )}
                <div className="talent-management-influencer-details-platform-details">
                  <Typography variant="body2">
                    {currentProfile.posts || 0}
                  </Typography>
                  <Typography
                    className="talent-management-influencer-details-rate-caption"
                    variant="body2"
                  >
                    {currentProfile.platform === "INSTAGRAM" && "Posts"}
                    {currentProfile.platform === "TIKTOK" && "Videos"}
                  </Typography>
                </div>
                <div className="talent-management-influencer-details-platform-details">
                  <Typography variant="body2">
                    {internationalNumberFormat(currentProfile.followers || 0)}
                  </Typography>
                  <Typography
                    className="talent-management-influencer-details-rate-caption"
                    variant="body2"
                  >
                    Followers
                  </Typography>
                </div>
              </div>
              {influencer?.socialMedia?.length > 1 && (
                <div className="talent-management-influencer-details-other-platform-link-container">
                  <Typography
                    onClick={(e) =>
                      setCurrentProfile(
                        influencer?.socialMedia.find(
                          (s) => s.platform !== currentProfile.platform
                        )
                      )
                    }
                    className="talent-management-influencer-details-other-platform-link"
                    variant="body2"
                  >
                    Or view{" "}
                    {
                      influencer?.socialMedia.find(
                        (s) => s.platform !== currentProfile.platform
                      )?.platform
                    }
                  </Typography>
                </div>
              )}
            </div>
            <div className="talent-management-influencer-details-description-container">
              <Typography variant="body2">{influencer.description}</Typography>
            </div>
          </div>
        )}
      </div>
      {!influencer.clientApprovalStatus && (
        <div className="talent-management-influencer-details-awaiting-container">
          <div
            className="talent-management-influencer-details-awaiting-btn"
            onClick={(e) => handleButtonAction(e, "approved")}
          >
            <CheckIcon />
            <Typography variant="body2">Approve</Typography>
          </div>
          <div
            className="talent-management-influencer-details-awaiting-btn talent-management-influencer-details-reject-btn"
            onClick={(e) => handleButtonAction(e, "rejected")}
          >
            <CloseIcon />
            <Typography variant="body2">Reject</Typography>
          </div>
          <div
            className="talent-management-influencer-details-awaiting-btn talent-management-influencer-details-discuss-btn"
            onClick={(e) => handleButtonAction(e, "discuss")}
          >
            <ChatBubbleIcon />
            <Typography variant="body2">Discuss</Typography>
          </div>
        </div>
      )}
      {influencer.clientApprovalStatus === "approved" && (
        <div className="talent-management-influencer-details-actioned-container">
          <div className="talent-management-influencer-details-actioned-btn">
            <CheckIcon />
            <Typography variant="body2">Approved</Typography>
          </div>
        </div>
      )}
      {influencer.clientApprovalStatus === "rejected" && (
        <div className="talent-management-influencer-details-actioned-container">
          <div className="talent-management-influencer-details-actioned-btn talent-management-influencer-details-reject-btn">
            <CloseIcon />
            <Typography variant="body2">Rejected</Typography>
          </div>
        </div>
      )}
    </div>
  );
};

export default InfluencerDetails;
