import React from "react";

const JobName = (props) => {
  const { job } = props;
  return (
    <div id="job-name">
      <div className="use-header-font job-title">{`[${job.id}] ${job.jobName}`}</div>
    </div>
  );
};

export default JobName;
