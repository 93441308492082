import Close from "@mui/icons-material/Close";
import {
  Autocomplete,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";

const AccountsDetailsDialog = (props) => {
  const [accountsDetail, setAccountsDetail] = useState();
  const [accountsOption, setAccountsOption] = useState();
  const [selectedContact, setSelectedContact] = useState(null);

  useEffect(() => {
    if (props.contactAccounts) {
      setAccountsDetail({
        emailAddress: props.contactAccounts.primaryAccountsEmail,
        phoneNumber: props.contactAccounts.accountsPhone,
      });
    }
  }, [props.contactAccounts]);

  const handleOnChange = (field, value) => {
    setAccountsDetail({
      ...accountsDetail,
      [field]: value,
    });
  };

  const handleSelectExistingContact = (newValue) => {
    setSelectedContact(newValue);
    setAccountsDetail(newValue);
  };

  const onChangeAccountsOption = (event) => {
    if (event.target.value === "MYSELF") {
      const jobContact = {
        firstName: props.contact.firstName,
        lastName: props.contact.lastName,
        emailAddress: props.contact.emailAddress,
        phoneNumber: props.contact.phoneNumber,
        companyId: props.companyId,
      };
      setAccountsDetail(jobContact);
      setSelectedContact(null);
    } else if (event.target.value === "NEW") {
      const newAccountDetails = {
        firstName: "",
        lastName: "",
        emailAddress: "",
        phoneNumber: "",
        companyId: props.companyId,
      };
      setAccountsDetail(newAccountDetails);
      setSelectedContact(null);
    } else if (event.target.value === "EXISTING") {
      setAccountsDetail(null);
    }
    setAccountsOption(event.target.value);
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleCloseAccountsDetails}
        aria-labelledby="form-dialog-title-contact"
        fullWidth
      >
        <DialogTitle
          id="form-dialog-title-company"
          className="sales-dialog-title"
        >
          Contact Details for Invoices
          <IconButton
            aria-label="close"
            onClick={props.handleCloseAccountsDetails}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {/* <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="accounts-set"
            row
            value={accountsOption}
            onChange={(e) => {
              onChangeAccountsOption(e);
            }}
          >
            <FormControlLabel
              value="MYSELF"
              control={<Radio />}
              label="Set as Myself"
            />
            {props.contacts && props.contacts.length > 0 && (
              <FormControlLabel
                value="EXISTING"
                control={<Radio />}
                label="Existing Contact"
              />
            )}

            <FormControlLabel
              value="NEW"
              control={<Radio />}
              label="New Contact"
            />
          </RadioGroup> */}

          {accountsOption === "EXISTING" && (
            <Autocomplete
              id="existing-contacts"
              size="small"
              noOptionsText="No contacts found."
              getOptionLabel={(option) =>
                option.firstName +
                " " +
                option.lastName +
                " - " +
                option.emailAddress
              }
              style={{ padding: 15 }}
              options={props.contacts.map((option) => option)}
              onChange={(e, newValue) => handleSelectExistingContact(newValue)}
              renderInput={(params) => (
                <TextField {...params} label="Choose a contact" />
              )}
            />
          )}
          {(accountsDetail || (accountsOption === "EXISTING" && selectedContact)) && (
            <div>
              {/* <div className="flex-div">
                <div className="accountDetail-textfield flex-row-components">
                  <TextField
                    id="accountDetail-first-name"
                    variant="outlined"
                    label="First Name"
                    size="small"
                    fullWidth
                    value={accountsDetail?.firstName}
                    onChange={(e) => {
                      handleOnChange("firstName", e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="flex-div">
                <div className="accountDetail-textfield flex-row-components">
                  <TextField
                    id="accountDetail-last-name"
                    variant="outlined"
                    label="Last Name"
                    size="small"
                    fullWidth
                    value={accountsDetail?.lastName}
                    onChange={(e) => {
                      handleOnChange("lastName", e.target.value);
                    }}
                  />
                </div>
              </div> */}
              <div className="flex-div">
                <div className="accountDetail-textfield flex-row-components">
                  <TextField
                    id="accountDetail-email"
                    variant="outlined"
                    label="Primary Accounts Email"
                    size="small"
                    fullWidth
                    value={accountsDetail?.emailAddress}
                    onChange={(e) => {
                      handleOnChange("emailAddress", e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="flex-div">
                <div className="accountDetail-textfield flex-row-components">
                  <TextField
                    id="accountDetail-phone"
                    variant="outlined"
                    label="Phone Number"
                    size="small"
                    fullWidth
                    value={accountsDetail?.phoneNumber}
                    onChange={(e) => {
                      handleOnChange("phoneNumber", e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="flex-div">
                <div className="accountDetail-textfield flex-row-components">
                  <Autocomplete
                    multiple
                    freeSolo
                    size="small"
                    id="tags-cc"
                    options={[]}
                    value={props.emailsCc}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Enter emails to receive a copy of the invoice"
                        placeholder="Type and press enter to insert multiple."
                      />
                    )}
                    onChange={(e, value) => {
                      props.setEmailsCc(value);
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleCloseAccountsDetails} color="primary">
            Cancel
          </Button>
          <Button
            disabled={accountsDetail == null}
            onClick={() => props.handleSaveAccountsDetails(accountsDetail)}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AccountsDetailsDialog;
