import React, { useEffect, useState } from "react";
import {
    Dialog,
    DialogTitle,
    IconButton,
    DialogContent,
    Grid,
    Divider
} from "@mui/material";
import {
    Close as CloseIcon
} from "@mui/icons-material";
import "./Msa.scss";
import SignatureComponent from "../Shared/SignatureComponent";

const MsaDialog = (props) => {
    const {
        open,
        handleClose,
        handleAcceptAndSign,
        company
    } = props;

    const [showSignatureComponent, setShowSignatureComponent] = useState(false);

    return (
        <Dialog
            open={open}
            onClose={(e, reason) => {
                if (reason && reason === "backdropClick"){
                    return;
                }
                handleClose();
            }}
            classes={{ paper: "msa-preview-dialog" }}
        >
            {showSignatureComponent && (
                <SignatureComponent
                    open={showSignatureComponent}
                    handleClose={() => setShowSignatureComponent(false)}
                    title={"Please sign below"}
                    handleAcceptAndSign={handleAcceptAndSign}
                />
            )}
            <DialogTitle>
                <div className="msa-dialog-title">
                    MASTER SERVICES AGREEMENT
                </div>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <div className="msa-document-container">
                    <iframe
                        key={"msa-document"}
                        title={"Approvals - MSA Document"}
                        src={`https://docs.google.com/document/d/${company.msaid}/preview`}
                        width={"100%"}
                        height={"100%"}
                        className={"iframe-scale-height"}
                    >
                    </iframe>
                </div>
                <div>
                    <button
                        className="use-main-font quote-button accept-sign-button"
                        onClick={() => setShowSignatureComponent(true)}
                    >
                        Accept & Sign
                    </button>
                </div>
            </DialogContent>
        </Dialog >
    )
}

export default MsaDialog;