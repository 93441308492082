import { CircularProgress } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';

const Download = (props) => {
  const [loadingPdf, setLoadingPdf] = useState(false);

  const handleDownloadPdf = async () => {
    setLoadingPdf(true)
    let res = await axios.get(`Quote/DownloadPdf/${props.job.id}`, {
      params: {
        cacheBustTimestamp: Date.now(), // prevents IE cache problems on re-download
      },
      responseType: "blob",
      headers: {
        "Accept": "application/pdf",
      },
    });
    const blob = new Blob([res.data], { type: "application/pdf" });
    // var newDownloadWindow = window.open();
    // // setTimeout to prevent browser from blocking the popup
    // setTimeout(() => {
    //   if (newDownloadWindow) {
    //     newDownloadWindow.location = window.URL.createObjectURL(blob);
    //   }
    // }, 500);

    var blobUrl = window.URL.createObjectURL(blob);
    // Create a link element
    const link = document.createElement("a");

    // Set link's href to point to the Blob URL
    link.href = blobUrl;
    link.download = props.job?.jobName ? `${props.job?.jobName}.pdf` : "file.pdf";

    // Append link to the body
    document.body.appendChild(link);

    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
    );

    // Remove link from body
    document.body.removeChild(link);

    setLoadingPdf(false)
  };

  return (
    <div style={{paddingBottom: 20}}>
      {loadingPdf ? (
        <div>
          <CircularProgress />
        </div>
      ) : (
        <button
          className="use-main-font quote-button download-quote-button"
          onClick={(e) => {
            handleDownloadPdf();
          }}
        >
          <SimCardDownloadOutlinedIcon /> Download PDF
        </button>
      )}
      <div>
        <br />
      </div>
    </div>
  );
};

export default Download;
