import React, { Fragment, useEffect, useState } from "react";
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import DialogWithHtmlContent from "../Shared/DialogWithHtmlContent";
import SharedDialog from "../Shared/SharedDialog";
import PoNumberDialog from "./PoNumberDialog";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import MsaDialog from "../MSA/MsaDialog";
import _ from "lodash";
import FileDownloadDoneIcon from "@mui/icons-material/FileDownloadDone";
import Download from "./Download";
import DialogWithDriveFileContent from "../Shared/DialogWithDriveFileContent";
import { formatNumber } from "../../utils/quoteUtils";
import CurrencyFormatter from "../Shared/CurrencyFormatter";
import { getCurrencies, isEmpty } from "../../utils/general";

const QuoteBottom = (props) => {
  const {
    job,
    termsHtml,
    setOpenAccountsDetailsDialog,
    createAccountsDetailAndConfirmJob,
    previewMode,
    setJobConfirmed,
    confirmationType,
    isReadOnly,
    setJob,
    company,
    setConfirmationType,
  } = props;
  const [total, setTotal] = useState();
  const [gst, setGst] = useState();
  const [totalCost, setTotalCost] = useState();
  const [termsCheckbox, setTermsCheckbox] = useState();
  const [dialogWithHtmlContentOpen, setDialogWithHtmlContentOpen] =
    useState(false);
  const [dialogAcceptTermsAndConditions, setDialogAcceptTermsAndConditions] =
    useState(false);
  const [confirmingJob, setConfirmingJob] = useState();
  const [showMsaDialog, setShowMsaDialog] = useState(false);
  const [msaFunctionToSave, setMsaFunctionToSave] = useState(null);
  const [currencySymbol, setCurrencySymbol] = useState(getCurrencies().find(c => c.value === job.currency).symbol);

  const closeDialog = () => {
    setDialogProps({
      ...dialogProps,
      open: false,
    });
  };

  const [dialogProps, setDialogProps] = useState({
    open: false,
    handleClose: Function,
    direction: "",
    title: "",
    text: "",
    buttonText: "",
  });
  const [poNumber, setPoNumber] = useState("");
  const [poNumberPopUp, setPoNumberPopUp] = useState(false);
  const [gstLabel, setGstLabel] = useState("");

  useEffect(() => {
    if (job) {
      let total = 0;
      let gst = 0;
      job.jobLines.forEach((jl) => {
        total += jl.exchangedTotal;
      });

      if (job.company !== null && job.company !== "") {
        setPoNumber(job.poNumber);
      }

      if (job.isRetainer) {
        switch (job.retainerType) {
          case "QUARTERLY":
            total = total * 3;
            break;
          case "YEARLY":
            total = total * 12;
            break;
          case "TOTAL":
            total = total * job.retainerDuration;
            break;
          default:
            break;
        }
      }

      if (
        job.company?.gstregistered &&
        (job.currency === "NZD" || isEmpty(job.currency))
      ) {
        gst = total * 0.15;
        setGstLabel("+GST");
      }
      const totalCost = total + gst;

      setTotal(total);
      setGst(gst);
      setTotalCost(totalCost);
    }
  }, [job]);

  useEffect(() => {
    if (confirmationType && confirmationType === "CONFIRM_JOB") {
      confirmJob();
    } else if (confirmationType && confirmationType === "ENTER_PO") {
      enterPO();
    }
  }, [confirmationType]);

  const acceptTermsAndConditions = (e) => {
    setTermsCheckbox(e.target.checked);
  };

  const displayEmailsCc = () => {
    if (job.company && job.company.accountsEmailsCc) {
      const emailsArr = job.company.accountsEmailsCc.split(";");
      return emailsArr.map((email) => <div className="emails-cc">{email}</div>);
    }
    return "";
  };

  const confirmJob = () => {
    setConfirmingJob(true);
    axios
      .post(`Quote/ConfirmJob/${job.id}`, {
        id: job.id,
        poNumber: poNumber === null ? "" : poNumber,
      })
      .then(() => {
        setJobConfirmed(true);
      })
      .catch((err) => {
        setDialogProps({
          open: true,
          handleClose: closeDialog,
          direction: "up",
          title: "Ooops!",
          text: "Something went wrong with this request. Please contact us on hello@magnesium.nz",
          buttonText: "Ok",
        });
      })
      .finally(() => {
        if (poNumberPopUp) {
          setPoNumberPopUp(false);
        }
      });
  };

  const enterPO = () => {
    setPoNumberPopUp(true);
  };

  const checkMsaSignature = (functionType) => {
    if (!job.company.hasSignedMsa) {//job.company.organisationFk > 1 && 
      setShowMsaDialog(true);
      setMsaFunctionToSave(functionType);
    }
  };

  const validateBeforeSubmitting = (functionToBeExecuted, functionType) => {
    if (!termsCheckbox && company.hasSignedMsa) {
      setDialogAcceptTermsAndConditions(true);
    } else if (!job.company.hasSignedMsa) {//job.company.organisationFk > 1 && 
      checkMsaSignature(functionType);
    } else if (
      job.company.primaryAccountsEmail &&
      job.company.companySize !== "NZME"
    ) {
      functionToBeExecuted();
    } else if (job.company.companySize === "NZME") {
      setConfirmationType("CONFIRM_JOB");
    } else {
      createAccountsDetailAndConfirmJob(functionType);
    }
  };

  const handleAcceptAndSign = (dataUrl) => {
    let sigForm = new FormData();
    sigForm.append("dataUrl", dataUrl);
    axios
      .post(`Quote/SaveMsa/${job.companyId}/${job.contactId}`, sigForm, {
        headers: { "content-type": "multipart/form-data" },
      })
      .then(({ data }) => {
        setShowMsaDialog(false);
        let jobClone = _.cloneDeep(job);
        jobClone.company = data;
        setJob(jobClone);
        if (
          job.company.primaryAccountsEmail &&
          job.company.companySize !== "NZME"
        ) {
          enterPO();
        } else if (job.company.companySize === "NZME") {
          setConfirmationType("CONFIRM_JOB");
        } else {
          createAccountsDetailAndConfirmJob(msaFunctionToSave);
        }
      })
      .catch((e) => {
        console.log(e);
      });

    // if (msaFunctionToSave === "CONFIRM_JOB") {
    // confirmJob();
    // } else if (msaFunctionToSave === "ENTER_PO") {
    //   createAccountsDetailAndConfirmJob(msaFunctionToSave)
    // }
  };

  const getTotalsHeader = (header) => {
    if (header === "SUBTOTAL") {
      if (job.isRetainer) {
        if (job.retainerType === "TOTAL") {
          return "RETAINER SUBTOTAL";
        } else {
          return `${job.retainerType} SUBTOTAL`;
        }
      }
    } else if (header === "GST") {
      if (job.isRetainer) {
        if (job.retainerType === "TOTAL") {
          return "RETAINER GST";
        } else {
          return `${job.retainerType} GST`;
        }
      }
    } else if (header === "TOTAL") {
      if (job.isRetainer) {
        if (job.retainerType === "TOTAL") {
          return "RETAINER TOTAL";
        } else {
          return `${job.retainerType} TOTAL`;
        }
      }
    } 

    return header
    
  };

  return (
    <div>
      {showMsaDialog && (
        <MsaDialog
          open={showMsaDialog}
          handleClose={() => {
            setShowMsaDialog(false);
          }}
          handleAcceptAndSign={handleAcceptAndSign}
          company={job.company}
        />
      )}
      {poNumberPopUp && (
        <PoNumberDialog
          poNumber={poNumber}
          setPoNumber={setPoNumber}
          open={poNumberPopUp}
          onClose={() => {
            closeDialog();
            setPoNumberPopUp(false);
            setPoNumber("");
          }}
          confirmJob={confirmJob}
        />
      )}
      {dialogAcceptTermsAndConditions && (
        <SharedDialog
          open={dialogAcceptTermsAndConditions}
          handleClose={() => {
            closeDialog();
            setDialogAcceptTermsAndConditions(false);
          }}
          direction="up"
          title="Terms of Services"
          text="Please accept our terms of services before proceeding"
          buttonText="Ok"
        />
      )}
      <DialogWithDriveFileContent
        isOpen={dialogWithHtmlContentOpen}
        handleClose={() => {
          setDialogWithHtmlContentOpen(false);
        }}
        driveId={termsHtml}
        title={"Terms And Conditions"}
      />
      <div id="quote-bottom-container">
        <div id="table-left">
          <table id="quote-bottom-table-left" className="use-main-font">
            <thead>
              {job.company.primaryAccountsEmail &&
                job.company.companySize !== "NZME" && (
                  <tr>
                    <th
                      align="left"
                      colSpan={3}
                      className="account-contact-header"
                    >
                      WHERE WE SEND THE INVOICES TO
                    </th>
                  </tr>
                )}
            </thead>
            <tbody className="quote-bottom-tbody">
              {job.company?.primaryAccountsEmail &&
                job.company.companySize !== "NZME" && (
                  <Fragment>
                    <tr>
                      <td>Email</td>
                      <td>{job.company?.primaryAccountsEmail}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Phone</td>
                      <td>{job.company?.accountsPhone}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Cc for Emails</td>
                      <td>{displayEmailsCc()}</td>
                      <td></td>
                    </tr>
                    <tr className="deposit change-contact-button-cel">
                      <td colSpan={3}>
                        {(!previewMode || !isReadOnly) && (
                          <button
                            className={`use-main-font quote-button change-button`}
                            onClick={(e) => {
                              setOpenAccountsDetailsDialog(true);
                            }}
                            disabled={previewMode || isReadOnly}
                          >
                            Change
                          </button>
                        )}
                      </td>
                    </tr>
                  </Fragment>
                )}
            </tbody>
          </table>
        </div>
        <div id="table-right">
          <table id="quote-bottom-table-right" className="use-main-font">
            <tr className="quote-bottom-tbody-tr">
              <td className="total-headings" width="70%" align="left">
                {getTotalsHeader("SUBTOTAL")}
              </td>
              <td width="30%" align="right" className="total-values">
                <CurrencyFormatter
                  amount={total}
                  appendZero
                  useDollar
                  currency={job.currency || "NZD"}
                />
              </td>
            </tr>
            {gst > 0 && (
              <>
                <tr className="quote-bottom-tbody-tr">
                  <td className="total-headings" align="left">
                    {getTotalsHeader("GST")}
                  </td>
                  <td align="right" className="total-values">
                    <CurrencyFormatter
                      amount={gst}
                      appendZero
                      useDollar
                      currency={job.currency || "NZD"}
                    />
                  </td>
                </tr>
                <tr className="quote-bottom-tbody-tr">
                  <td className="total-headings" align="left">
                    {getTotalsHeader("TOTAL")}
                  </td>
                  <td align="right" className="total-values">
                    <CurrencyFormatter
                      amount={totalCost}
                      appendZero
                      useDollar
                      currency={job.currency || "NZD"}
                    />
                  </td>
                </tr>
              </>
            )}
          </table>
        </div>
      </div>
      {job.invoice && job.invoice.length > 0 && job.retainerType !== null && (
        <Fragment>
          <tr className="deposit confirmation-first-line">
            <td className="use-header-font" colSpan={3}>
              This retainer will be invoiced monthly
            </td>
          </tr>
        </Fragment>
      )}
      {job.invoice &&
        job.invoice.length > 0 &&
        job.retainerType === null && (
          <Fragment>
            <tr className="deposit confirmation-first-line">
              <td className="use-header-font" colSpan={3}>
                {job.paymentType === "FULL_POST_PAYMENT" ||
                job.paymentType === "END_OF_MONTH_BILLING"
                  ? "We will send you an invoice for"
                  : job.paymentType === "FULL_PRE_PAYMENT"
                  ? "On sign off we will invoice you"
                  : "We will send you a 50% deposit invoice on sign off."}
              </td>
            </tr>
            <tr className="deposit">
              <td
                className="use-header-font"
                colSpan={3}
                style={{ borderBottom: 0 }}
              >
                {job.paymentType === "FULL_POST_PAYMENT" ||
                job.paymentType === "END_OF_MONTH_BILLING"
                  ? "this project completion."
                  : job.paymentType === "FULL_PRE_PAYMENT"
                  ? "the full amount of this project"
                  : `The balance will be due on completion.`}
              </td>
            </tr>
          </Fragment>
        )}
      {/* {job.company?.companySize === "NZME" && (
        <Fragment>
          <tr className="deposit confirmation-first-line">
            <td className="use-header-font" colSpan={3}>
              This project will be invoiced to you via NZME
            </td>
          </tr>
        </Fragment>
      )} */}
      {!isReadOnly && (
        <div style={{ marginBottom: 20 }}>
          <div className="confirmation-terms">
            {company.hasSignedMsa && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <FormGroup>
                  <FormControlLabel
                    onChange={acceptTermsAndConditions}
                    control={<Checkbox size="small" />}
                    className="terms-conditions-label"
                    label="by approving this you are continuing to accept our terms of services"
                  />
                </FormGroup>
                <div
                  id="terms-and-conditions-link"
                  onClick={() => setDialogWithHtmlContentOpen(true)}
                >
                  [Read More]
                </div>
              </div>
            )}
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: 20 }}>
              <button
                className="use-main-font quote-button confirm-quote-button"
                onClick={(e) => validateBeforeSubmitting(enterPO, "ENTER_PO")}
                disabled={previewMode || confirmingJob}
              >
                <FileDownloadDoneIcon /> Confirm
                {confirmingJob && (
                  <span>
                    <CircularProgress className="loading-circular" />
                  </span>
                )}
              </button>
            </div>
            <Download job={job} />
          </div>
        </div>
      )}
      {isReadOnly && (
        <div style={{ marginTop: 20 }}>
          <Download job={job} />
        </div>
      )}
    </div>
  );
};

export default QuoteBottom;
