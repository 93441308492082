import React from "react";
import PortraitIcon from "@mui/icons-material/Portrait";
import { Button, Typography } from "@mui/material";
import dayjs from "dayjs";
import "./clientDetails.scss";
import DateRangeIcon from "@mui/icons-material/DateRange";
const ClientDetails = ({ project, handleUpdateAllStatuses }) => {
  return (
    <>
      <div className="talent-management-client-details-container">
        <div className="talent-management-client-details">
          <Typography
            className="default-heading-small"
          >
            Client:{" "}
          </Typography>
          <Typography className="default-text-small">{project.customerName}</Typography>
        </div>
        <div className="talent-management-client-details">
          <Typography
            className="default-heading-small"
          >
            Date:{" "}
          </Typography>
          <Typography className="default-text-small">
            {dayjs().format("DD/MM/YYYY")}
          </Typography>
        </div>
      </div>
      <div className="talent-management-title-wrapper">
        <div className="talent-management-title">{`${project.jobId ? `[${project.jobId}]` : ``}`}{` ${project.projectName}`}</div>
        <Button
          className="talent-management-client-details-btn"
          color="success"
          variant="contained"
          size="small"
          onClick={(e) => handleUpdateAllStatuses("approved")}
        >
          Approve All Influencers
        </Button>
      </div>
    </>
  );
};

export default ClientDetails;
